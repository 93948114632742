import {
    GET_PARTICIPANT_LIST,
    GET_PARTICIPANT_CALENDAR,
    GET_PARTICIPANT,
    SAVE_PARTICIPANT,
    SAVE_PARTICIPATION,
    DELETE_PARTICIPANT,

    GET_PEOPLE_LIST,
    GET_PEOPLE,
    SAVE_PEOPLE,
    DELETE_PEOPLE,

    GET_DATA_LIST,
    GET_DATA,
    SAVE_DATA,
    DELETE_DATA, GET_STATS_LIST, GET_STATS, SAVE_STATS, DELETE_STATS,
} from './types';
import axios from 'axios';
import { ApiURL } from "../../config";

export const getDailyLogs = () => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getDailyLogs`, tokenConfig(getState));
        dispatch ({
            type: GET_DATA_LIST,
            payload: res.data
        });
    }
    catch (err) {
        console.log('error', err);
    }
};
export const editDailyLog = (id) => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getDailyLog/${id}`, tokenConfig(getState));
        dispatch ({
            type: GET_DATA_LIST,
            payload: res.data
        });
    }
    catch (err) {
        console.log('error', err);
    }
};
export const saveDailyLog = (id, doy, q1, q2, q3, q4, q5, q6) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/saveDailyLog`, {id, doy, q1, q2, q3, q4, q5, q6}, tokenConfig(getState));
        dispatch ({
            type: GET_DATA_LIST,
            payload: res.data
        });
    }
    catch (err) {
        console.log('error', err);
    }
};



export const getReport = (reportType, dateStart, dateEnd) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/getReport`, {reportType, dateStart, dateEnd}, tokenConfig(getState));
        dispatch ({
            type: GET_DATA_LIST,
            payload: res.data
        });
    }
    catch (err) {
        console.log('error', err);
    }
};

export const getLotteryList = () => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getLotteryList`, tokenConfig(getState));
        dispatch ({
            type: GET_DATA_LIST,
            payload: res.data
        });
    }
    catch (err) {
        console.log('error', err);
    }
};

export const runLottery = () => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/runLottery`, tokenConfig(getState));
        dispatch ({
            type: GET_DATA_LIST,
            payload: res.data
        });
    }
    catch (err) {
        console.log('error', err);
    }
};

export const selectLotteryLocation = (postData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/selectLotteryLocation`, { postData }, tokenConfig(getState));
        dispatch ({
            type: GET_DATA_LIST,
            payload: res.data
        });
    }
    catch (err) {
        console.log('error', err);
    }
};

export const saveSelectLocation = (postData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/saveSelectLocation`, { postData }, tokenConfig(getState));
        dispatch ({ type: SAVE_DATA, payload: res.data });
    }
    catch (err) {
        console.log('error', err);
    }
};

export const getParticipantList = () => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getParticipantList`, tokenConfig(getState));
        dispatch ({
            type: GET_PARTICIPANT_LIST,
            payload: res.data
        });
    }
    catch (err) {
        console.log('error', err);
    }
};

export const getParticipantCalendar = (id, calendarDate) => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getParticipantCalendar/${id}/${calendarDate}`, tokenConfig(getState));
        dispatch ({
            type: GET_PARTICIPANT_CALENDAR,
            payload: res.data
        });
    }
    catch (err) {
        console.log('error', err);
    }
};
export const getParticipant = (userId) => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getParticipantList/${userId}`, tokenConfig(getState));
        dispatch ({
            type: GET_PARTICIPANT,
            payload: res.data
        });
    }
    catch (err) {
        console.log('error', err);
    }
};
export const saveParticipant = (postData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/saveParticipant`, { postData }, tokenConfig(getState));
        dispatch ({ type: SAVE_PARTICIPANT, payload: res.data });
    }
    catch (err) {
        console.log('error', err);
    }
};
export const deleteParticipant = (postData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/deleteParticipant`, { postData }, tokenConfig(getState));
        dispatch ({ type: DELETE_PARTICIPANT, payload: res.data });
    }
    catch (err) {
        console.log('error', err);
    }
};
export const saveParticipation = (userId, participationDate, participation) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/saveParticipation`, { userId, participationDate, participation }, tokenConfig(getState));
        dispatch ({ type: SAVE_PARTICIPATION, payload: res.data });
    }
    catch (err) {
        console.log('error', err);
    }
};





export const getCategoryList = () => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getCategoryList`, tokenConfig(getState));
        dispatch ({
            type: GET_PARTICIPANT_LIST,
            payload: res.data
        });
    }
    catch (err) {
        console.log('error', err);
    }
};
export const getCategory = (id) => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getCategoryList/${id}`, tokenConfig(getState));
        dispatch ({
            type: GET_PARTICIPANT,
            payload: res.data
        });
    }
    catch (err) {
        console.log('error', err);
    }
};
export const saveCategory = (postData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/saveCategory`, { postData }, tokenConfig(getState));
        dispatch ({ type: SAVE_PARTICIPANT, payload: res.data });
    }
    catch (err) {
        console.log('error', err);
    }
};
export const deleteCategory = (postData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/deleteCategory`, { postData }, tokenConfig(getState));
        dispatch ({ type: DELETE_PARTICIPANT, payload: res.data });
    }
    catch (err) {
        console.log('error', err);
    }
};




export const getPeopleList = () => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getPeopleList`, tokenConfig(getState));
        dispatch ({
            type: GET_PEOPLE_LIST,
            payload: res.data
        });
    }
    catch (err) {
        console.log('error', err);
    }
};
export const getPeople = (userId) => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getPeopleList/${userId}`, tokenConfig(getState));
        dispatch ({
            type: GET_PEOPLE,
            payload: res.data
        });
    }
    catch (err) {
        console.log('error', err);
    }
};
export const savePeople = (postData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/savePeople`, { postData }, tokenConfig(getState));
        dispatch ({ type: SAVE_PEOPLE, payload: res.data });
    }
    catch (err) {
        console.log('error', err);
    }
};
export const deletePeople = (postData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/deletePeople`, { postData }, tokenConfig(getState));
        dispatch ({ type: DELETE_PEOPLE, payload: res.data });
    }
    catch (err) {
        console.log('error', err);
    }
};



export const getStatsList = () => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getStatsList`, tokenConfig(getState));
        dispatch ({
            type: GET_STATS_LIST,
            payload: res.data
        });
    }
    catch (err) {
        console.log('error', err);
    }
};
export const getStats = (userId) => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getStatsList/${userId}`, tokenConfig(getState));
        dispatch ({
            type: GET_STATS,
            payload: res.data
        });
    }
    catch (err) {
        console.log('error', err);
    }
};
export const saveStats = (postData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/saveStats`, { postData }, tokenConfig(getState));
        dispatch ({ type: SAVE_STATS, payload: res.data });
    }
    catch (err) {
        console.log('error', err);
    }
};
export const deleteStats = (postData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/deleteStats`, { postData }, tokenConfig(getState));
        dispatch ({ type: DELETE_STATS, payload: res.data });
    }
    catch (err) {
        console.log('error', err);
    }
};




export const getLocationList = () => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getLocationList`, tokenConfig(getState));
        dispatch ({
            type: GET_DATA_LIST,
            payload: res.data
        });
    }
    catch (err) {
        console.log('error', err);
    }
};
export const getLocations = (userId) => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getLocationList/${userId}`, tokenConfig(getState));
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        console.log('error', err);
    }
};
export const saveLocations = (postData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/saveLocation`, { postData }, tokenConfig(getState));
        dispatch ({ type: SAVE_DATA, payload: res.data });
    }
    catch (err) {
        console.log('error', err);
    }
};
export const deleteLocation = (postData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/deleteLocation`, { postData }, tokenConfig(getState));
        dispatch ({ type: DELETE_DATA, payload: res.data });
    }
    catch (err) {
        console.log('error', err);
    }
};














export const tokenConfig = (getState) => {
    // GET TOKEN FROM STATE
    const token = getState().auth.token;

    // HEADERS
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type":"application/json"
        }
    };

    // IF TOKEN ADD TO HEADERS
    if (token) {
        config.headers['Authorization'] = token;
    }
    return config;
};
