import React, {Component} from 'react';
import loading from '../../assets/logo.png';
class Loading extends Component {
    render() {
        return (
            <div className="text-center">
                <br /><br /><br /><br />
                <img src={loading} alt="Loading" className="logoSpin" style={{width: 150}} />
                <br /><br /><br /><br />
            </div>
        )
    }
}
export default Loading;