import React, {Component} from 'react';
import {Button} from "react-bootstrap";
import {ToastContainer} from "react-toastr";
import {ApiURL} from "../../config";
import store from '../../client/store';

let container;
class Dashboard extends Component {
    componentDidMount() {

    }
    rerunLottery = () => {
        const token = store.getState().auth.token;
        fetch(`${ApiURL}/rerunLottery`, {
            method: 'GET',
            headers: {'Authorization': token},
        })
        .then(res => res.json())
        .then(output => {
            if (output.success === 1 || output.success === '1')
            {
                container.success('Lottery has been RERUN');
            } else {
                container.success('ERROR: ' + output.message);
            }
        })
    };
    resetLottery = () => {
        const formData = new FormData();
        const token = store.getState().auth.token;
        fetch(`${ApiURL}/resetLottery`, {
            method: 'POST',
            headers: {'Authorization': token},
            body: formData
        })
        .then(res => res.json())
        .then(output => {
            if (output.success === 1 || output.success === '1')
            {
                container.success('Lottery has been Reset');
            } else {
                container.success('ERROR: ' + output.message);
            }
        })
    };
    unCheckInParticipants = () => {
        const formData = new FormData();
        const token = store.getState().auth.token;
        fetch(`${ApiURL}/unCheckInAllParticipants`, {
            method: 'POST',
            headers: {'Authorization': token},
            body: formData
        })
            .then(res => res.json())
            .then(output => {
                if (output.success === 1 || output.success === '1')
                {
                    container.success('All participants have been Un-checked in.');
                } else {
                    container.success('ERROR: ' + output.message);
                }
            })
    };
    render() {
        //const {user} = this.props.auth;
        return (<div className="container">
            <div className="row justify-content-center">
                <div className="col-12 my-4">
                    <h1 className="text-center text-white">Admin Dashboard</h1>
                </div>
                <div className="col-12">
                    <div className="alert alert-warning">
                        <div className="alert-message">BE CAREFUL - These action can reset the lottery and mark people as no paid who have paid.</div>
                    </div>
                </div>
                <div className="col-12 text-center">

                    <Button onClick={() => this.rerunLottery() } className="btn btn-light m-2">
                        Re-Run Today's Lottery
                    </Button>

                    <br /><br />
                    <Button onClick={() => this.resetLottery() } className="btn btn-danger m-2">
                        Reset Today's Lottery
                    </Button>
                    <Button onClick={() => this.unCheckInParticipants()} className="btn btn-danger m-2">
                        Un Checkin All Participants
                    </Button>
                    <br /><br />
                    <br /><br />


                    <a href="/settings" className="btn btn-sm btn-secondary"><i className="fas fa-long-arrow-alt-left" /> Back to Settings</a>
                </div>
            </div>
            <ToastContainer
                ref={ref => container = ref}
                className="toast-top-center"
            />
        </div>)
    }
}

export default Dashboard;
