import React, {Component, Fragment}  from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import { getLocationList, deleteLocation } from "../../client/actions/apiActions";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'

class Location extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            successMessage: '',
            errorMessage: ''
        };
        this.selectedCheckboxes = new Set();
    };

    componentDidMount() {
        this.props.getLocationList();
    }
    componentWillUnmount() {
        this.setState({
            locations: [],
            successMessage: '',
            errorMessage: ''
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextState) {
        this.setState({
            locations: nextProps.data.locations
        });
    }

    deleteLocation = (locationId, locationName) => {
        const deleteOptions = {
            title: 'Confirm Delete',
            message: `Are you sure you wish to delete this Location "${locationName}"`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => { this.props.deleteLocation(locationId); this.setState({successMessage: "Location has been deleted!"}); }
                },
                {
                    label: 'No',
                    onClick: () => { console.log("Location clicked no"); }
                }
            ]
        };

        confirmAlert(deleteOptions);
    };

    render() {
        const {
            locations,
            successMessage
        } = this.state;

            return (
                <div className="container content">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="text-center text-white mt-4">Locations</h1>

                            {successMessage && <div className="col-12"><div className="alert alert-success">{successMessage}</div></div>}
                            <Link className="btn btn-secondary my-2 mr-2" to="/settings"><i className="fas fa-long-arrow-alt-left" /> Back To Settings</Link>
                            <Link className="btn btn-warning my-2" to="/settings/locations/edit/0">Add Location</Link>
                            <table className="table table-striped bg-white">
                                <thead className="thead-dark">
                                    <tr>
                                        <th>Number</th>
                                        <th>Coords</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {locations.length !== 0 && <Fragment>
                                    {locations.map((location, index) => (
                                        <tr key={index}>
                                            <td>{location.number}</td>
                                            <td>{location.coords}</td>
                                            <td className="text-right">
                                                <Link className="btn btn-warning btn-sm m-1" to={"/settings/locations/edit/" + location.id}><i className="fas fa-pencil-alt" /></Link>
                                                <button className="btn btn-danger btn-sm m-1" onClick={()=>this.deleteLocation(location.id, location.name)}><i className="fas fa-times" /></button>
                                            </td>
                                        </tr>
                                    ))}
                                </Fragment>}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            );
    }
}

Location.propTypes = {
    data: PropTypes.object.isRequired,
    getLocationList: PropTypes.func.isRequired,
    deleteLocation: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    data: state.pages.data
});

export default connect( mapStateToProps, { getLocationList, deleteLocation })(Location);