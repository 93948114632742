import React, {Component}  from 'react';
import { Link } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css'

class Reports extends Component {
    render() {
        return (<div className="container content">
            <div className="row">
                <div className="col-12">
                    <h1 className="text-center text-white mt-4">Reports</h1>

                    <Link className="btn btn-secondary my-2 mr-2" to="/settings"><i className="fas fa-long-arrow-alt-left"/> Back To Settings</Link><br/><br/>
                    <p>
                        <Link className="btn btn-warning my-2" to="/settings/reports/attendance-totals">Attendance Totals</Link> {' '}
                        <Link className="btn btn-warning my-2" to="/settings/reports/attendance-list">Attendance List</Link> {' '}
                        <Link className="btn btn-warning my-2" to="/settings/reports/attendance-list-buskers">Busker Attendance</Link> {' '}
                        <Link className="btn btn-warning my-2" to="/settings/reports/attendance-list-artists">Artist Attendance</Link>
                    </p>
                </div>
            </div>
        </div>);
    }
}
export default (Reports);
