import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Loading from "../components/layout/Loading";

const PrivateRoute = ({ component: Component, auth, ...rest}) => (
    <Route
        {...rest}
        render={props => {
            //console.log("PrivateRoute auth", auth)
            if (auth.isLoading || auth.isAuthenticated === null) {
                return <Loading />;
            } else if (!auth.isAuthenticated) {
                return <Redirect to={`/login`} />;
            } else {
                return <Component {...props} auth={auth} />;
            }
        }}
    />
);

PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
