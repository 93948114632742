import React from 'react';

const FormInputText = ({name, label, colSize}) => {
    let divColSize;
    if (colSize === 0) divColSize = 0;
    if (colSize === 1) divColSize = 12;
    if (colSize === 2) divColSize = 6;
    if (colSize === 3) divColSize = 4;
    if (colSize === 4) divColSize = 8;
    if (colSize === 5) divColSize = 3;
    return (<div className={`col-12 align-bottom col-sm-${divColSize}`}>
        <div className="container p-0">
            <div className="form-group row">
                <label htmlFor={name} className="col-12 m-0 pt-2 pb-2">{label}</label>
            </div>
        </div>
    </div>);
};

export default FormInputText