import React, {Component, Fragment}  from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import { getReport } from "../../client/actions/apiActions";
import FormInput from "../form/FormInput";
import {toastr} from "react-redux-toastr";
import Calendar from "./Participant_Calendar";


class Stats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [],
            data: [],
            dataTotals: [],
            error: [],
            dataResults: [],
            dateStart: '',
            dateEnd: '',
            successMessage: '',
            reportType: '',
            errorMessage: '',
            reports: {
                "attendance-totals": "Attendance Daily Totals",
                "attendance-list": "Attendance List",
            },

            timeOut: 5000,
            showCloseButton: true,
            progressBar: true,
            position: "top-right"
        };
        this.selectedCheckboxes = new Set();
    };
    componentDidMount() {
        let reportType = this.props.match.params.report;

        let newDate = new Date();

        let curDay = String(newDate.getDate());
        if (curDay.length === 1) curDay = '0' + curDay;

        let curMonth = String(newDate.getMonth()+1);
        if (curMonth.length === 1) curMonth = '0' + curMonth;

        let doy = newDate.getFullYear() + "-" + curMonth + "-" + curDay;

        let cur2Day = String(newDate.getDate());
        if (cur2Day.length === 1) cur2Day = '0' + cur2Day;

        let cur2Month = String(newDate.getMonth());
        if (cur2Month.length === 1) cur2Month = '0' + cur2Month;

        let doy2 = newDate.getFullYear() + "-" + cur2Month + "-" + cur2Day;

        this.setState({reportType:reportType, dateStart: doy2, dateEnd: doy});
    }
    componentWillUnmount() {
        this.setState({
            data: []
        });
    }
    onChange = (type, e, name) => {
        let tmpValue = '';
        if (type === 'text')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'phone')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'email')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'textarea')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'toggle')
        {
            if (e.target.checked === true) tmpValue = '1';
            if (e.target.checked === false) tmpValue = '0';
        }
        else if (type === 'image')
        {
            tmpValue = e;
        }
        else if (type === 'editor')
        {
            tmpValue = e.target.getContent();
        }
        else if (type === 'select' || type === 'dropdown')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'password')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'checkbox')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'radio')
        {
            tmpValue = e.target.value;
        }
        else
        {
            tmpValue = e.target.value;
        }

        this.setState({[name]: tmpValue});
    };

    showToastr = (type, title, message) => {
        const options = {
            timeOut: parseInt(this.state.timeOut),
            showCloseButton: this.state.showCloseButton,
            progressBar: this.state.progressBar,
            position: this.state.position
        };
        const toastrInstance =
            type === "info"
                ? toastr.info
                : type === "warning"
                ? toastr.warning
                : type === "error"
                    ? toastr.error
                    : toastr.success;

        toastrInstance(
            title,
            message,
            options
        );
    }
    searchReport = () => {
        const { reportType, dateStart, dateEnd } = this.state;
        let error = false;

        if (reportType === '') {
            error = true;
            this.showToastr("error", "FAILED", "Report type is blank");
        }

        if (dateStart === '') {
            error = true;
            this.showToastr("error", "FAILED", "Start date is blank");
        }

        if (dateEnd === '') {
            error = true;
            this.showToastr("error", "FAILED", "End date is blank");
        }
        if (error === false) this.props.getReport(reportType, dateStart, dateEnd);
        this.hideCalendar();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            this.setState({columns: this.props.data.columns, data: this.props.data.data, dataTotals: this.props.data.dataTotals})
        }
    }

    showCalendar = (pid) => {

        this.setState({showCalendar: true, calendarId:pid})
    }
    hideCalendar = () => {
        this.setState({showCalendar: false, calendarId:0})
    }

    render() {
        const {
            reports,
            columns,
            data,
            error,
            dateStart,
            dateEnd,
            reportType,
            showCalendar,
            calendarId,
            dataTotals
        } = this.state;

        return (<div className="container content">
            <div className="row">
                <div className="col-12 text-white">
                    <h1 className="text-center mt-4">Reports</h1>
                    <h2 className="text-center mt-4">{reports[reportType]}</h2>

                    <Link className="btn btn-secondary my-2 mr-2" to="/settings/reports"><i className="fas fa-long-arrow-alt-left" /> Back To Reports</Link>
                    <div className="container">
                        <div className="row">
                            <div className="col text-white">Search Date Range</div>
                            <div className="col">
                                <FormInput
                                    type="date"
                                    name="dateStart"
                                    defaultValue=""
                                    showLabel={false}
                                    colSize="2"
                                    value={dateStart}
                                    onChange={this.onChange}
                                    error={error.dateStart}
                                    label="Start"
                                    placeholder="Start"
                                />
                            </div>
                            <div className="col">
                                <FormInput
                                    type="date"
                                    name="dateEnd"
                                    defaultValue=""
                                    showLabel={false}
                                    colSize="2"
                                    value={dateEnd}
                                    onChange={this.onChange}
                                    error={error.dateEnd}
                                    label="End"
                                    placeholder="End"
                                />
                            </div>
                            <div className="col">
                                <button className="btn btn-warning btn-sm" onClick={this.searchReport}>Search</button> {' '}
                                <button className="btn btn-secondary btn-sm" onClick={window.print}>Print</button>
                            </div>
                        </div>
                    </div>

                    {data && data.length !== 0 && <table className="table table-striped bg-white">
                        <thead className="thead-dark">
                            <tr>
                                {columns.map((columnName, index) => (
                                    <th key={index}>{columnName}</th>
                                ))}
                                {(reportType === 'attendance-list' || reportType === 'attendance-list-artists' || reportType === 'attendance-list-buskers') && <th>&nbsp;</th>}
                            </tr>
                        </thead>
                        <tbody className="text-dark">
                            {data.map((dataRow, index) => {
                                return (<Fragment key={index}>
                                    <tr>
                                        {columns.map((columnName, index) => {
                                            return (<td key={index}>{dataRow[index]}</td>);
                                        })}
                                        {(reportType === 'attendance-list' || reportType === 'attendance-list-artists' || reportType === 'attendance-list-buskers') && <td>
                                            <button onClick={() => this.showCalendar(dataRow[6]) } className="btn btn-success btn-sm m-1"><i className="fas fa-calendar-alt" /></button>
                                        </td>}
                                    </tr>
                                    {(reportType === 'attendance-list' || reportType === 'attendance-list-artists' || reportType === 'attendance-list-buskers') && showCalendar === true && calendarId === dataRow[6] && <Calendar
                                        key={dataRow[6]}
                                        calendarId={dataRow[6]}
                                        hideCalendar={this.hideCalendar}
                                    />}
                                </Fragment>)}
                            )}
                            {reportType === 'attendance-totals' && <tr className="bg-dark text-light">
                                <td>&nbsp;</td>
                                <td>{dataTotals.avgArtists} avg artists</td>
                                <td>{dataTotals.avgBuskers} avg buskers</td>
                            </tr>}
                        </tbody>
                    </table>}

                    {data && data.length === 0 && <Fragment><hr className="text-white border-white" noshade="true" /><h2 className="text-center">No results Found</h2></Fragment>}
                    <br /><br /><br /><br />
                </div>
            </div>
        </div>);
    }
}

Stats.propTypes = {
    data: PropTypes.object.isRequired,
    getReport: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    data: state.pages.data
});

export default connect( mapStateToProps, { getReport })(Stats);
