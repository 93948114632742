import React, {Component} from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import store from './client/store';
import PrivateRoute from "./common/PrivateRoute";
import ReduxToastr from "react-redux-toastr";
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'

// TEMPLATES
import HeaderTemplate from './components/layout/Header';
import FooterTemplate from './components/layout/Footer';

// PAGES
import Dashboard from './components/pages/Dashboard';
import RunLottery from './components/pages/RunLottery';
import CheckIn from './components/pages/CheckIn';
import Settings from './components/pages/Settings';
import DailyLog from './components/pages/DailyLog';
import GlobalSettings from './components/pages/SettingsGlobal';
import Participants from './components/pages/Participants';
import Participants_Edit from './components/pages/Participants_Edit';
import Users from './components/pages/Users';
import Users_Edit from './components/pages/Users_Edit';
import Locations from './components/pages/Locations';
import Locations_Edit from './components/pages/Locations_Edit';
import Category from './components/pages/Category';
import Category_Edit from './components/pages/Category_Edit';
import Stats from './components/pages/Stats';
import Reports from './components/pages/Reports';
import ReportsView from './components/pages/ReportsView';
import CashRegister from './components/pages/CashRegister';

import Error404 from './components/pages/Error404';
import Login from './components/pages/Login';

import { loadUser } from "./client/actions/auth";

// CSS
import './assets/App.css';

class App extends Component {

  componentDidMount() {
    store.dispatch(loadUser());
  }

  render() {
    return (<Provider store={store}>
      <Router>
        <React.Fragment>
          <HeaderTemplate {...this.props} updateSearchTerm={this.updateSearchTerm}/>
          <Switch>
            <PrivateRoute exact path="/" component={Dashboard} />
            <PrivateRoute exact path="/run-lottery" component={RunLottery} />

            <PrivateRoute exact path="/cash-register" component={CashRegister} />

            <PrivateRoute exact path="/log" component={DailyLog} />

            <PrivateRoute exact path="/settings" component={Settings} />
            <PrivateRoute exact path="/settings/global-settings" component={GlobalSettings} />

            <PrivateRoute exact path="/settings/stats" component={Stats} />

            <PrivateRoute exact path="/settings/users" component={Users} />
            <PrivateRoute exact path="/settings/users/edit/:id" component={Users_Edit} />

            <PrivateRoute exact path="/settings/participants" component={Participants} />
            <PrivateRoute exact path="/settings/participants/edit/:id" component={Participants_Edit} />

            <PrivateRoute exact path="/settings/locations" component={Locations} />
            <PrivateRoute exact path="/settings/locations/edit/:id" component={Locations_Edit} />

            <PrivateRoute exact path="/settings/categories" component={Category} />
            <PrivateRoute exact path="/settings/categories/edit/:id" component={Category_Edit} />

            <PrivateRoute exact path="/settings/reports" component={Reports} />
            <PrivateRoute exact path="/settings/reports/:report" component={ReportsView} />

            <PrivateRoute exact path="/logout" component={Login} />
            <Route exact path="/login" component={Login} location={this.props.location} />
            <Route exact path="/check-in" component={CheckIn} />
            <PrivateRoute component={Error404} />
          </Switch>
          <FooterTemplate {...this.props} />
        </React.Fragment>
      </Router>
      <ReduxToastr
          timeOut={5000}
          newestOnTop={true}
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
      />
    </Provider>);
  }
}

export default App;
