import React, {Component, Fragment} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../client/actions/auth';

class Header extends Component {

    static propTypes = {
        auth: PropTypes.object.isRequired,
        logout: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }
    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }
    componentDidMount() {

    }
    render()
    {
        const {isAuthenticated, user} = this.props.auth;
        const pathname  = window.location.pathname;
        const collapsed = this.state.collapsed;
        const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
        if (user !== null)
        {
            let adminLinks = (
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item"><Link className="nav-link" onClick={this.toggleNavbar} to={"/"}>Dashboard</Link></li>
                    <li className="nav-item"><Link className="nav-link" onClick={this.toggleNavbar} to={"/settings"}>Settings</Link></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.props.logout} href="/"><i className="fas fa-power-off"/></a></li>
                </ul>
            );
            let supervisorLinks = (
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item"><Link className="nav-link" onClick={this.toggleNavbar} to={"/"}>Dashboard</Link></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.props.logout} href="/"><i className="fas fa-power-off"/></a></li>
                </ul>
            );

            if (pathname !== '/check-in' && pathname !== '/' && pathname !== '/log') {
                return (
                    <nav className="navbar navbar-expand-lg navbar-dark bg-dark text-light BorderShadow noprint">
                        <div className="container">
                            <button onClick={this.toggleNavbar} className={`${classTwo}`} type="button"
                                    data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"/>
                            </button>
                            <div className={`${classOne}`} id="navbarSupportedContent">
                                {isAuthenticated === true && <Fragment>
                                    {user.user.userType === 'A' && adminLinks}
                                    {user.user.userType === 'S' && supervisorLinks}
                                </Fragment>}
                                {isAuthenticated === false && <Fragment>
                                    &nbsp;
                                </Fragment>}
                            </div>
                        </div>
                    </nav>
                );
            } else {
                return (null);
            }
        }
        else
        {
            return (<div>&nbsp;</div>);
        }
    }
}

const mapStateToProps = state => ({
    auth:state.auth
});

export default connect(mapStateToProps, { logout })(Header);