import React, {Component} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from "../../client/actions/auth";
import logo from '../../assets/logo.png';

class Login extends Component {
    state = {
        username: '',
        password: ''
    };

    static propTypes = {
        login: PropTypes.func.isRequired,
        isAuthenticated: PropTypes.bool
    };

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props)
        {
            if (this.props.isAuthenticated === true) this.props.history.push('/');
        }
    }

    onChange = e => this.setState({[e.target.name]: e.target.value});
    onSubmit = e => {
        e.preventDefault();
        this.props.login(this.state.username, this.state.password);
    };

    render() {
        const { username, password }= this.state;
        return (
            <div className="container mb-4">
                <div className="row">
                    <div className="col-md-6 m-auto">
                        <div className="card card-body border-warning mt-5">

                            <div className="row">
                                <div className="col-12 my-2 text-center">
                                    <img src={logo} className="img-fluid" alt="Company Logo" style={{width: '400px', height: 'auto'}} border="0" />
                                </div>
                            </div>
                            <form onSubmit={this.onSubmit}>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label text-left">Username</label>
                                    <div className="col-sm-8"><input
                                        type="text"
                                        name="username"
                                        onChange={this.onChange}
                                        className="form-control"
                                        autoComplete="section-red username"
                                        value={username}
                                    /></div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label text-left">Password</label>
                                    <div className="col-sm-8"><input
                                        type="password"
                                        name="password"
                                        onChange={this.onChange}
                                        className="form-control"
                                        autoComplete="section-red current-password"
                                        value={password}
                                    /></div>
                                </div>
                                <button className="btn btn-block btn-secondary">LOG IN</button>
                                <br/><br/>
                                <a href="/check-in" className="btn btn-block btn-secondary">PARTICIPANT CHECK IN</a>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
   isAuthenticated : state.auth.isAuthenticated
});

export default connect(mapStateToProps, {login} )(Login);