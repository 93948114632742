import React, {Component, Fragment} from 'react';
import FormInput from "../form/FormInput";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { getParticipantCalendar, saveParticipation } from "../../client/actions/apiActions";
import Loading from "../layout/Loading";
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";


let newDate = new Date();
let curMonth = String(newDate.getMonth()+1);
if (curMonth.length === 1) curMonth = '0' + curMonth;
let defaultCalendarDate = newDate.getFullYear() + "-" + curMonth;

class CalendarDay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dayInfo: {},
            statusInfo: '',
            participationInfo: '0'
        };
    };
    componentDidMount() {
        this.setState({
            dayInfo: this.props.dayInfo,
            statusInfo: this.props.statusInfo,
            participationInfo: this.props.participationInfo
        });
    }
    render () {
        const { statusInfo, participationInfo, dayInfo} = this.state;
        //console.log("Day", statusInfo, participationInfo, dayInfo);
        if (typeof(statusInfo) !== 'undefined' && statusInfo === 'Cancelled - Weather')
        {
            return (<Fragment>
                <div className="col m-0 border text-center text-dark bg-light small">
                    {dayInfo.dn}<br />
                    {dayInfo.dl}
                    <sup><small>{dayInfo.s}</small></sup>
                </div>
                {(dayInfo.dow === 6 || dayInfo.dow === "6") && <div className="col-12">
                    <hr className="bg-dark m-0 p-0"/>
                </div>}
            </Fragment>);
        }
        else if (typeof(participationInfo) !== 'undefined')
        {
            return (<Fragment>
                <div className="col m-0 border text-center text-light bg-success small" onClick={()=>this.props.updateParticipation(dayInfo, participationInfo)}>
                    {dayInfo.dn}<br />
                    {dayInfo.dl}
                    <sup><small>{dayInfo.s}</small></sup>
                </div>
                {(dayInfo.dow === 6 || dayInfo.dow === "6") && <div className="col-12">
                    <hr className="bg-dark m-0 p-0"/>
                </div>}
            </Fragment>);
        }
        else if (typeof(statusInfo) !== 'undefined' && statusInfo === 'Active')
        {
            return (<Fragment>
                <div className="col m-0 border text-center text-dark bg-warning small" onClick={()=>this.props.updateParticipation(dayInfo, participationInfo)}>
                    {dayInfo.dn}<br />
                    {dayInfo.dl}
                    <sup><small>{dayInfo.s}</small></sup>
                </div>
                {(dayInfo.dow === 6 || dayInfo.dow === "6") && <div className="col-12">
                    <hr className="bg-dark m-0 p-0"/>
                </div>}
            </Fragment>);
        }
        else if (typeof(statusInfo) !== 'undefined' && statusInfo === 'Cancelled - Participants')
        {
            return (<Fragment>
                <div className="col m-0 border text-center text-light bg-info small" onClick={()=>this.props.updateParticipation(dayInfo, participationInfo)}>
                    {dayInfo.dn}<br />
                    {dayInfo.dl}
                    <sup><small>{dayInfo.s}</small></sup>
                </div>
                {(dayInfo.dow === 6 || dayInfo.dow === "6") && <div className="col-12">
                    <hr className="bg-dark m-0 p-0"/>
                </div>}
            </Fragment>);
        }
        else
        {
            return (<Fragment>
                <div className="col m-0 border text-center small">
                    {dayInfo.dn}<br />
                    {dayInfo.dl}
                    <sup><small>{dayInfo.s}</small></sup>
                </div>
                {(dayInfo.dow === 6 || dayInfo.dow === "6") && <div className="col-12">
                    <hr className="bg-dark m-0 p-0"/>
                </div>}
            </Fragment>);
        }
    }
}

class Participant_Calendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            viewMode: 'loading',
            calendar: [],
            participation: [],
            eventStatus: [],
            viewCalendarDate: defaultCalendarDate,
            editDayInfo: [],
            editParticipation: 0
        };
    };
    componentDidMount() {
        this.props.getParticipantCalendar(this.props.calendarId, defaultCalendarDate);
    }
    componentWillUnmount() {
        this.setState({calendar: [], data: [], participation: [], eventStatus: []});
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            if (this.props.dataCalendar.pageType === "getParticipantCalendar") {
                this.setState({
                    viewMode: 'calendar',
                    calendar: this.props.dataCalendar.calendar,
                    participation: this.props.dataCalendar.participation,
                    eventStatus: this.props.dataCalendar.eventStatus
                });
            }
            if (this.props.dataCalendar.pageType === "saveParticipation") {
                this.setState({viewMode: 'loading'});
                this.closeModal();
                this.props.getParticipantCalendar(this.props.calendarId, this.state.viewCalendarDate);
            }
        }
    }
    onChange = (type, e, name) => {
        this.setState({[name]: e.target.value});
        if (name === "viewCalendarDate") {
            this.props.getParticipantCalendar(this.props.calendarId, e.target.value);
            this.setState({viewMode: "loading"});
        }
    };
    updateParticipation = (dayInfo, participationInfo) => {
        if (typeof(participationInfo) === 'undefined') participationInfo=0;
        this.setState({ editDayInfo:dayInfo, editParticipation:participationInfo });
        this.openModal();
    }
    saveParticipation = () => {
        const { editDayInfo, editParticipation } = this.state;
        this.props.saveParticipation(this.props.calendarId, editDayInfo.fd, editParticipation);
    }

    openModal = () => {
        this.setState({ showModal: true });
    }
    closeModal = () => {
        this.setState({ showModal: false });
    }
    render() {
        const {
            showModal,
            viewMode,
            calendar,
            participation,
            eventStatus,
            editDayInfo,
            editParticipation,
            viewCalendarDate
        } = this.state;

        let startDow = 0;
        let endDow = 0;
        let cDow = 0;
        let endHtml = [];
        let calendarKeys = Object.keys(calendar)[0];
        if (calendarKeys) startDow = calendar[calendarKeys]["dow"];

        let startHtml = [];
        while (startDow > 0) {
            startHtml.push(1);
            startDow--;
        }

        if (viewMode === 'loading')
        {
            return (<tr className="bg-dark text-white">
                <td colSpan="6">
                    <Loading />
                </td>
            </tr>);
        }
        else
        {
            return (<tr className="bg-dark text-white">
                <td colSpan="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <button className="btn btn-sm btn-warning" onClick={() => this.props.hideCalendar}>Hide</button>
                                {' '} &nbsp;  &nbsp; &nbsp;
                                <small>
                                    Legend: {' '}
                                    <button className="btn btn-sm btn-light">Cancelled - Weather</button> {' '}
                                    <button className="btn btn-sm btn-info">Cancelled - Participants</button> {' '}
                                    <button className="btn btn-sm btn-warning">Active - Did Not Participate</button> {' '}
                                    <button className="btn btn-sm btn-success">Participated</button>
                                </small>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <FormInput
                                    name="viewCalendarDate"
                                    value={viewCalendarDate}
                                    type="month"
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>

                        <div className="row">
                            {startHtml.length !== 0 && <Fragment>
                                {startHtml.map((outputHtml, index) => {
                                    return (<div key={index} className="col border text-center m-0">&nbsp;</div>);
                                })}
                            </Fragment>}
                            {Object.keys(calendar).map((key, index) => {
                                let dataInfo = calendar[key];
                                cDow = dataInfo.dow;
                                let pKey = participation[key];
                                let sKey = eventStatus[key];
                                endHtml=[];
                                endDow = cDow;
                                while (endDow < 6) {
                                    endHtml.push(1);
                                    endDow++;
                                }
                                return (<CalendarDay
                                    key={index}
                                    statusInfo={sKey}
                                    participationInfo={pKey}
                                    dayInfo={dataInfo}
                                    calendarId={this.props.calendarId}
                                    updateParticipation={this.updateParticipation}
                                />);
                            })}
                            {endHtml.length !== 0 && <Fragment>
                                {endHtml.map((outputHtml, index) => {
                                    return (<div key={index} className="col border text-center m-0">&nbsp;</div>);
                                })}
                            </Fragment>}
                        </div>
                    </div>

                    <Modal show={showModal} onHide={()=>this.closeModal()}>
                        <Modal.Header closeButton>
                            <Modal.Title>Update Participation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="text-center">{editDayInfo.fd}</div>
                            <FormInput
                                name="editParticipation"
                                type="radio"
                                value={editParticipation}
                                valueOptions={[
                                    {name: 'Participated', id: 1, value: 1},
                                    {name: 'Did not Particpate', id: 0, value: 0}
                                ]}
                                onChange={this.onChange}
                            />

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={()=>this.closeModal()}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={()=>this.saveParticipation()}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </td>
            </tr>);
        }

    }
}

Participant_Calendar.propTypes = {
    dataCalendar: PropTypes.object.isRequired,
    getParticipantCalendar: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    dataCalendar: state.pages.dataCalendar
});

export default connect( mapStateToProps, { getParticipantCalendar, saveParticipation })(Participant_Calendar);