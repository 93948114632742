import {
    GET_LOGIN_STATUS,
    GET_PARTICIPANT_LIST,
    GET_PARTICIPANT,
    SAVE_PARTICIPANT,
    SAVE_PARTICIPATION,
    DELETE_PARTICIPANT,
    GET_PARTICIPANT_CALENDAR,

    GET_PEOPLE_LIST,
    GET_PEOPLE,
    SAVE_PEOPLE,
    SAVE_PEOPLE_SUCCESS,
    DELETE_PEOPLE_ERROR,
    DELETE_PEOPLE,

    GET_DATA_LIST,
    GET_DATA,
    SAVE_DATA,
    SAVE_DATA_SUCCESS,
    DELETE_DATA_ERROR,
    DELETE_DATA,
} from "../actions/types";

const initialState = {
    data: {},
    dataCalendar: {},
};

export default function(state = initialState, action) {
    switch (action.type) {

        case GET_LOGIN_STATUS:
            return {
                ...state,
                loginStatus: action.payload
            };

        case GET_PARTICIPANT_CALENDAR:
        case SAVE_PARTICIPATION:
            return {
                ...state,
                dataCalendar: action.payload
            };

        case GET_DATA_LIST:
        case GET_DATA:
        case SAVE_DATA:
        case SAVE_DATA_SUCCESS:
        case DELETE_DATA_ERROR:
        case DELETE_DATA:
        case GET_PEOPLE_LIST:
        case GET_PEOPLE:
        case SAVE_PEOPLE:
        case SAVE_PEOPLE_SUCCESS:
        case DELETE_PEOPLE_ERROR:
        case DELETE_PEOPLE:
        case GET_PARTICIPANT_LIST:
        case GET_PARTICIPANT:
        case SAVE_PARTICIPANT:
        case DELETE_PARTICIPANT:
            return {
                ...state,
                data: action.payload
            };

        default:
            return state;
    }
}