import React, {Component, Fragment}  from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { saveParticipant, getParticipant } from "../../client/actions/apiActions";
import store from '../../client/store';
import Loading from "../layout/Loading"
import FormInput from "../form/FormInput";
import FormInputFileDragAndDrop from "../form/FormInputFileDragAndDrop";
import Link from "react-router-dom/Link";
import { ApiURL } from "../../config";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import gearsImage from "../../assets/gears.gif";

class Participant_Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded:false,
            errorMessage: '',
            cancelEdit: false,
            errors : {},
            additionalInformation: '',
            address: '',
            city: '',
            email: '',
            firstName: '',
            lotteryGroup:'',
            id: '',
            lastName: '',
            materialsDescription: '',
            performanceDescription: '',
            phone: '',
            state: '',
            zip: '',
            category: '',
            isLightboxOpen: false,
            lightboxPhotoIndex: 0,
            files: [],
            filesToUpload: [],
            fileList: [],
            totalDocuments: 0,
            pendingFiles: [],
            errorFiles: [],
            categoryList: [],
        }
    };
    componentDidMount() {
        const {id} = this.props.match.params;
        if (id !== '' && id !== 0) {
            try {
                this.props.getParticipant(id);
            }
            catch (e) {
                console.log("e", e);
            }
        } else {
            this.setState({isLoaded: true});
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data.participant !== this.props.data.participant && typeof(this.props.data.participant) !== 'undefined') {
            let tmpFiles = this.props.data.participant.files;
            if (tmpFiles == null) tmpFiles = [];

            let category = this.props.data.participant.category;
            if (category === null) category = '';


            this.setState({
                isLoaded: true,
                additionalInformation: this.props.data.participant.additionalInformation,
                address: this.props.data.participant.address,
                city: this.props.data.participant.city,
                email: this.props.data.participant.email,
                firstName: this.props.data.participant.firstName,
                id: this.props.data.participant.id,
                lotteryGroup: this.props.data.participant.lotteryGroup,
                lastName: this.props.data.participant.lastName,
                materialsDescription: this.props.data.participant.materialsDescription,
                performanceDescription: this.props.data.participant.performanceDescription,
                phone: this.props.data.participant.phone,
                files: tmpFiles,
                state: this.props.data.participant.state,
                zip: this.props.data.participant.zip,

                category: category,
                categoryList: this.props.data.categoryList,
            });
        }
        if (this.props.data.success === 1 ||this.props.data.success === '1') {
            this.props.history.push('/settings/participants');
        }

    }
    componentWillUnmount() {
        this.setState({
            isLoaded:false,
            errorMessage: '',
            cancelEdit: false,
            errors : {},
            additionalInformation: '',
            address: '',
            city: '',
            email: '',
            firstName: '',
            id: '',
            lastName: '',
            materialsDescription: '',
            performanceDescription: '',
            phone: '',
            referenceImage1: '',
            referenceImage2: '',
            referenceImage3: '',
            referenceImage4: '',
            referenceImage5: '',
            referenceImage6: '',
            referenceImage7: '',
            state: '',
            zip: '',
        });
    }

    onChange = (type, e, name) => {
        let tmpValue = '';
        if (type === 'text')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'phone')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'email')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'textarea')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'toggle')
        {
            if (e.target.checked === true) tmpValue = '1';
            if (e.target.checked === false) tmpValue = '0';
        }
        else if (type === 'image')
        {
            tmpValue = e;
        }
        else if (type === 'editor')
        {
            tmpValue = e.target.getContent();
        }
        else if (type === 'select' || type === 'dropdown')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'password')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'checkbox')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'radio')
        {
            tmpValue = e.target.value;
        }
        else
        {
            tmpValue = e.target.value;
        }

        this.setState({[name]: tmpValue});
    };
    onDeleteImage = (e, name) => { e.preventDefault(); this.setState({[name]: ''}); }
    onChangeRadio = (name, value) => {
        this.setState({
            [name]: value
        });
    };

    onUpload = (e) => {
        console.log("onUpload");
        const files = Array.from(e.target.files);
        const { id } = this.props.match.params;
        const token = store.getState().auth.token;
        const formData = new FormData();
        this.setState({ uploading: true });

        let imageFieldName = e.target.name;
        formData.append('name', imageFieldName);
        formData.append('id', id);

        files.forEach((file, i) => {
            formData.append(i, file);
        });

        fetch(`${ApiURL}/image-upload`, {
            method: 'POST',
            body: formData,
            withCredentials: true,
            headers: {'Authorization' : token}
        })
            .then(res => res.json())
            .then(images => {

                if (images.success === 1) {
                    this.setState({
                        uploading: false,
                        [imageFieldName] : images.fileName
                    });
                }
            })
    };
    onUploadFile = (uploadFiles) => {
        console.log("onUploadFile");
        const files = Array.from(uploadFiles);
        const formData = new FormData();
        const token = store.getState().auth.token;
        this.setState({ uploadWarning: '', saveMessage: "", saveMessageType: ""});
        let uploadError = 0;
        const { id } = this.props.match.params;
        formData.append('id', id);

        if (uploadError !== 1) {
            files.forEach((file, i) => {
                if (this.state.fileList.includes(file.name)) {
                    this.setState({ uploadWarning: this.state.uploadWarning + " " + file.name });
                } else {
                    this.setState({ pendingFiles: [...this.state.pendingFiles, file.name] });
                    formData.append(i, file);
                }
            });

            // SET UPLOADING TO TRUE
            this.setState({ uploading: true });
            fetch(`${ApiURL}/image-upload`, {
                method: 'POST',
                headers: {'Authorization': token},
                body: formData
            })
                .then( res => res.json())
                .then(filesUploaded => {

                    if (typeof filesUploaded.documents !== 'undefined')
                    {
                        filesUploaded.documents.map((file,index) => {
                            if (file.status === 1)
                            {
                                this.setState({
                                    files: [...this.state.files, file],
                                    fileList: [...this.state.fileList, file.fileName],
                                    pendingFiles: this.state.pendingFiles.filter(filename => filename !== file.fileNameOrig)
                                });
                            }
                            else
                            {
                                this.setState({
                                    errorFiles: [...this.state.errorFiles, {error: file.statusReason, name: file.fileNameOrig}],
                                    fileList: [...this.state.fileList, file.fileName],
                                    pendingFiles: this.state.pendingFiles.filter(filename => filename !== file.fileNameOrig)
                                });
                            }
                            return(true);
                        });
                    }
                    this.setState({totalDocuments: this.state.files.length});
                })
        }
    };
    saveParticipant = (e) => {
        console.log("saveParticipant");
        if (typeof(e) !== 'undefined') e.preventDefault();
        const {id} = this.props.match.params;
        let noerror = true;
        let errors = {};
        const {
            firstName,
            lastName,
            email,
            phone,
            address,
            city,
            state,
            zip,
            lotteryGroup,
            files,
            category,

            additionalInformation,
            materialsDescription,
            performanceDescription
        } = this.state;

        if (firstName === '') {
            noerror = false;
            errors = {...errors, firstName: 'You left the participant "First Name" blank'};
        }

        if (noerror === true) {
            let saveData = {
                id,
                firstName,
                lastName,
                email,
                phone,
                address,
                city,
                state,
                zip,
                category,
                lotteryGroup,
                files,
                additionalInformation,
                materialsDescription,
                performanceDescription
            };
            this.props.saveParticipant( saveData );
            //this.props.history.push('/settings/participants');
        } else {
            this.setState({errors});
        }
    };

    viewImage = (imageNum) => {
        console.log("viewImage");
        this.setState({
            lightboxPhotoIndex: imageNum,
            isLightboxOpen: true
        })
    }

    deleteImage = (e, fileNameToDelete) => {
        console.log("deleteImage");
        e.preventDefault();

        console.log(fileNameToDelete, this.state.files);
        this.setState({
            files: this.state.files.filter(file => file.fileName !== fileNameToDelete)
        });
    };

    render() {
        const {
            errorMessage,
            errors,
            isLoaded,
            id,
            firstName,
            lastName,
            email,
            phone,
            address,
            city,
            state,
            zip,
            lotteryGroup,
            additionalInformation,
            materialsDescription,
            performanceDescription,
            lightboxPhotoIndex,
            totalDocuments,
            saveMessageType,
            saveMessage,
            files,
            category,
            categoryList,
            uploadWarning,
            pendingFiles,

            errorFiles,
            isLightboxOpen
        } = this.state;

        if (isLoaded === true) {
            return (
                <div className="container content bg-white mt-4">
                    <form name="editHotelForm" id="editHotelForm" autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                        <div className="col-12 mt-4">
                            {id !== 0 && <h1 className="text-center">Edit Participant : {firstName} {lastName}</h1>}
                            {id === 0 && <h1 className="text-center">Add Participant</h1>}
                        </div>
                        {errorMessage && <div className="col-12"><div className="alert alert-danger">{errorMessage}</div></div>}
                        <div className="col-sm-6 text-left mb-3"><Link to="/settings/participants" className="btn btn-secondary">Back</Link></div>
                        <div className="col-sm-6 text-right mb-3"><button type="button" className="btn btn-success" onClick={() => this.saveParticipant()}>SAVE PARTICIPANT</button></div>
                        <div className="col-12"><hr noshade="true" /></div>
                    </div>

                    <h3>Lottery Group</h3>
                    <FormInput
                        type="text"
                        onChange={this.onChange}

                        label="Lottery Group"
                        id="lotteryGroup"
                        name="lotteryGroup"
                        value={lotteryGroup}
                        placeholder="enter A B or C"
                        error={errors.lotteryGroup}
                    />
                    <h3>Artist Info</h3>
                    <FormInput
                        type="dropdown"
                        onChange={this.onChange}

                        label="Category"
                        id="category"
                        name="category"
                        value={category}
                        dropdown={categoryList}
                        placeholder="select a category .."
                        error={errors.category}
                    />
                    <FormInput
                        type="text"
                        onChange={this.onChange}

                        label="First Name"
                        id="firstName"
                        name="firstName"
                        value={firstName}
                        placeholder="enter participant first name .."
                        error={errors.firstName}
                    />
                    <FormInput
                        type="text"
                        onChange={this.onChange}

                        label="Last Name"
                        id="lastName"
                        name="lastName"
                        value={lastName}
                        placeholder="enter participant last name .."
                        error={errors.lastName}
                    />
                    <FormInput
                        type="text"
                        onChange={this.onChange}

                        label="Email"
                        id="email"
                        name="email"
                        value={email}
                        placeholder="enter participant email .."
                        error={errors.email}
                    />
                    <FormInput
                        type="text"
                        onChange={this.onChange}

                        label="Phone"
                        id="phone"
                        name="phone"
                        value={phone}
                        placeholder="enter participant phone .."
                        error={errors.phone}
                    />
                    <FormInput
                        type="text"
                        onChange={this.onChange}

                        label="Address"
                        id="address"
                        name="address"
                        value={address}
                        placeholder="enter participant address .."
                        error={errors.address}
                    />
                    <FormInput
                        type="text"
                        onChange={this.onChange}

                        label="City"
                        id="city"
                        name="city"
                        value={city}
                        placeholder="enter participant city .."
                        error={errors.city}
                    />
                    <FormInput
                        type="text"
                        onChange={this.onChange}

                        label="State"
                        id="state"
                        name="state"
                        value={state}
                        placeholder="enter participant state .."
                        error={errors.state}
                    />
                    <FormInput
                        type="text"
                        onChange={this.onChange}

                        label="Zip"
                        id="zip"
                        name="zip"
                        value={zip}
                        placeholder="enter participant zip .."
                        error={errors.zip}
                    />

                    <h3>Referenece Images</h3>
                    <FormInputFileDragAndDrop handleDrop={this.onUploadFile}>
                        {totalDocuments !== 0 && <div className="col-12 text-right">Total Files: {totalDocuments}</div>}
                        {saveMessageType === 0 && <div className="text-center alert alert-danger py-2 mb-2 rounded">{saveMessage}</div>}
                        {saveMessageType === 1 && <div className="text-center alert alert-success py-2 mb-2 rounded">{saveMessage}</div>}

                        <div className="DragDropFiles">
                            {uploadWarning && <div className="alert alert-danger py-2 mb-2 rounded">A file with the same name has already been uploaded, if the file you are uploading is a different file then rename it and try again. : {uploadWarning}</div>}
                            <div className="container">
                                <div className="row">
                                    {files && <Fragment>
                                        {files.map((file, index) => {
                                            return (<div key={index}
                                                className="col p-0 photoBox text-right"
                                                style={{backgroundImage: `url("https://app.sunsetsatpier60.com/applicationFiles/${file.fileName}")`, backgroundSize: `100%`}}
                                                >
                                                <button onClick={() => this.viewImage(index)} className="btn btn-sm btn-primary"><i className="fas fa-eye" /></button> {' '}
                                                <button onClick={(e) => this.deleteImage(e, file.fileName)} className="btn btn-sm btn-danger"><i className="fas fa-trash-alt" /></button>
                                            </div>);
                                        })}
                                    </Fragment>}
                                    {pendingFiles && <Fragment>
                                        {pendingFiles.map((file, index) =>{
                                            return (<div className="col p-0 photoBox text-center">
                                                <br />
                                                <br />
                                                <br />
                                                <img
                                                    src={gearsImage}
                                                    className="img-thumbnail img-fluid cursorPointer"
                                                    alt="Pending"
                                                />
                                            </div>);
                                        })}</Fragment>}
                                    {errorFiles && <Fragment>
                                {errorFiles.map((file, index) =>
                                    <div key={index} className="container">
                                        <div className="form-group row border-bottom pb-2 bg-dark text-light"><label
                                            htmlFor="fileName0" className="col-sm-12 m-0 pt-2 pb-2">File Name: {file.name}</label>
                                        </div>
                                        <div className="col-12 mb-3 rounded bg-danger text-light">Status: {file.error}</div>
                                    </div>
                                )}</Fragment>}
                                </div>
                            </div>
                        </div>
                    </FormInputFileDragAndDrop>
                    {isLightboxOpen && files && lightboxPhotoIndex && (
                        <Lightbox
                            mainSrc={"https://app.sunsetsatpier60.com/applicationFiles/" + files[lightboxPhotoIndex].fileName}
                            nextSrc={"https://app.sunsetsatpier60.com/applicationFiles/" + files[(lightboxPhotoIndex + 1) % files.length].fileName}
                            prevSrc={"https://app.sunsetsatpier60.com/applicationFiles/" + files[(lightboxPhotoIndex + files.length - 1) % files.length].fileName}
                            onCloseRequest={() => this.setState({ lightboxPhotoIndex:0, isLightboxOpen: false })}
                            onMovePrevRequest={() =>
                                this.setState({
                                    lightboxPhotoIndex: (lightboxPhotoIndex + files.length - 1) % files.length,
                                })
                            }
                            onMoveNextRequest={() =>
                                this.setState({
                                    lightboxPhotoIndex: (lightboxPhotoIndex + 1) % files.length,
                                })
                            }
                        />
                    )}

                    <h3>Additional Information</h3>
                    <FormInput
                        type="textarea"
                        onChange={this.onChange}

                        label="Additional Information"
                        id="additionalInformation"
                        name="additionalInformation"
                        value={additionalInformation}
                        placeholder="enter additional information .."
                        error={errors.additionalInformation}
                    />
                    <FormInput
                        type="textarea"
                        onChange={this.onChange}

                        label="Materials Description"
                        id="materialsDescription"
                        name="materialsDescription"
                        value={materialsDescription}
                        placeholder="enter materials description .."
                        error={errors.materialsDescription}
                    />
                    <FormInput
                        type="textarea"
                        onChange={this.onChange}

                        label="Performance Description"
                        id="performanceDescription"
                        name="performanceDescription"
                        value={performanceDescription}
                        placeholder="enter performance description .."
                        error={errors.performanceDescription}
                    />

                    <div className="row">
                        <div className="col-12"><hr noshade="true" /></div>
                        <div className="col-sm-6 text-left mb-3"><Link to="/settings/participants" className="btn btn-secondary">Back</Link></div>
                        <div className="col-sm-6 text-right mb-3"><button type="button" className="btn btn-success" onClick={() => this.saveParticipant()}>SAVE PARTICIPANT</button></div>
                    </div>
                    </form>
                </div>
            )
        } else  {
            return (<Loading />);
        }
    }
}

Participant_Edit.propTypes = {
    data: PropTypes.object.isRequired,
    saveParticipant: PropTypes.func.isRequired,
    getParticipant: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    data: state.pages.data
});

export default connect( mapStateToProps, { saveParticipant, getParticipant })(Participant_Edit);