import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";

class Footer extends Component {
    render() {
        const { user } = this.props.auth;
        const { pathname } = this.props.location;

        if (user !== null && pathname !== '/check-in' && pathname !== '/')
        {
            return (<Fragment>
                <br/><br/><br/><br/><br/><br/>
                <footer className="page-footer noprint mt-4">
                    <div className="container-fluid font-small bg-dark text-white">
                        <div className="container">
                            <div className="row pt-3">
                                <div className="col-md-12">
                                    <p>© Sunsets At Pier 60 | All rights reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </Fragment>);
        }
        else
        {
            return (null);
        }
    }
}

const mapStateToProps = state => ({
    auth:state.auth
});

export default connect(mapStateToProps, {  })(Footer);