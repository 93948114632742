import React, {Component}  from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import { getDailyLogs, editDailyLog, saveDailyLog } from "../../client/actions/apiActions";
import 'react-confirm-alert/src/react-confirm-alert.css'
import FormInput from "../form/FormInput";
import Loading from "../layout/Loading";

class DailyLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logResults: [],
            successMessage: '',
            errorMessage: '',
            viewCalendarMonth: '',
            viewCalendarYear: '',
            viewMode: 'loading',

            doy : '',
            recordId : 0,
            q1 : '',
            q2 : '',
            q3 : '',
            q4 : '',
            q5 : '',
            q6 : ''
        };
        this.selectedCheckboxes = new Set();
    };

    componentDidMount() {
        this.props.getDailyLogs();
        let newDate = new Date();

        let curDay = String(newDate.getDate());
        if (curDay.length === 1) curDay = '0' + curDay;

        let curMonth = String(newDate.getMonth()+1);
        if (curMonth.length === 1) curMonth = '0' + curMonth;

        let doy = newDate.getFullYear() + "-" + curMonth + "-" + curDay;
        this.setState({ doy: doy });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            // logResults
            if (this.props.data.pageType === "saveDailyLog") {
                if (this.props.data.success === "1" || this.props.data.success === 1) {
                    this.props.getDailyLogs();
                    this.resetState();
                } else {
                    this.setState({errorMessage: this.props.data.errorMessage });
                }

            }
            if (this.props.data.pageType === "getDailyLogs") {
                this.setState({logResults: this.props.data.dailyLog, viewMode: "list"});
            }
            if (this.props.data.pageType === "editDailyLog") {
                const { doy, q1, q2, q3, q4, q5, q6 } = this.props.data.dailyLog;
                this.setState({doy, q1, q2, q3, q4, q5, q6, viewMode: "Edit"});
            }
        }
    }

    componentWillUnmount() {
        this.setState({
            participants : [],
            successMessage : '',
            errorMessage : ''
        });
    }

    resetState = () => {
        let newDate = new Date();

        let curDay = String(newDate.getDate());
        if (curDay.length === 1) curDay = '0' + curDay;

        let curMonth = String(newDate.getMonth()+1);
        if (curMonth.length === 1) curMonth = '0' + curMonth;

        let doy = newDate.getFullYear() + "-" + curMonth + "-" + curDay;

        this.setState({
            viewMode : 'loading',
            errorMessage: '',
            doy : doy,
            recordId : 0,
            q1 : '',
            q2 : '',
            q3 : '',
            q4 : '',
            q5 : '',
            q6 : ''
        })
    }
    addDailyLog = () => {
        this.setState({viewMode : 'Add', recordId : 0})
    }
    cancelEditDailyLog = () => {

        let newDate = new Date();

        let curDay = String(newDate.getDate());
        if (curDay.length === 1) curDay = '0' + curDay;

        let curMonth = String(newDate.getMonth()+1);
        if (curMonth.length === 1) curMonth = '0' + curMonth;

        let doy = newDate.getFullYear() + "-" + curMonth + "-" + curDay;

        this.setState({
            viewMode : 'list',
            doy : doy,
            recordId : 0,
            errorMessage: '',
            q1 : '',
            q2 : '',
            q3 : '',
            q4 : '',
            q5 : '',
            q6 : ''
        })
    }
    editDailyLog = (id) => {
        // DO DB LOOK UP
        this.props.editDailyLog(id);
        this.setState({recordId : id});
    }
    saveDailyLog = () => {
        const { recordId, doy, q1, q2, q3, q4, q5, q6 } = this.state;
        this.props.saveDailyLog(recordId, doy, q1, q2, q3, q4, q5, q6);
    }

    onChange = (type, e, name) => {
        let tmpValue = '';
        if (type === 'text')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'phone')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'email')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'textarea')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'toggle')
        {
            if (e.target.checked === true) tmpValue = '1';
            if (e.target.checked === false) tmpValue = '0';
        }
        else if (type === 'image')
        {
            tmpValue = e;
        }
        else if (type === 'editor')
        {
            tmpValue = e.target.getContent();
        }
        else if (type === 'select' || type === 'dropdown')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'password')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'checkbox')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'radio')
        {
            tmpValue = e.target.value;
        }
        else
        {
            tmpValue = e.target.value;
        }

        this.setState({[name]: tmpValue});
    };

    render() {
        const {logResults, successMessage, errorMessage, viewMode, doy, q1, q2, q6} = this.state;

        if (viewMode === 'loading') {
            return (<Loading />);
        }

        if (viewMode === 'list') {
            return (<div className="container content">
                <div className="row">
                    <div className="col-12">
                        <h1 className="text-center text-white mt-4">Daily Log</h1>

                        {successMessage && <div className="col-12"><div className="alert alert-success">{successMessage}</div></div>}
                        <Link className="btn btn-secondary my-2 mr-2" to="/"><i className="fas fa-long-arrow-alt-left" /> Back To Dashboard</Link>
                        <button className="btn btn-warning my-2" onClick={()=>this.addDailyLog()}>Add a Daily Log</button>
                        <table className="table table-striped table-sm bg-white">
                            <thead className="thead-dark">
                                <tr>
                                    <th>Date</th>
                                    <th>Manager</th>
                                    <th>Event Status</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                            {logResults.length !== 0 && logResults.map((log, index) => {
                                return (<tr key={index}>
                                    <td>{log.date}</td>
                                    <td>{log.q1}</td>
                                    <td>{log.q2}</td>
                                    <td className="text-right nowrap">
                                        <button className="btn btn-warning btn-sm " onClick={()=>this.editDailyLog(log.id)}><i className="fas fa-pencil-alt" /></button>
                                    </td>
                                </tr>);
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>);
        }

        if (viewMode === "Edit" || viewMode === "Add") {
            return (<div className="container content">

                <div className="row">
                    <div className="col-12 text-white">
                        <h1 className="text-center text-white mt-4">{viewMode} Daily Log</h1>

                        {errorMessage && <div className="row"><div className="col-12"><div className="alert alert-danger">{errorMessage}</div></div></div>}

                        <FormInput
                            name="doy"
                            type="date"
                            value={doy}
                            label="Date of Log"
                            colSize={1}
                            onChange={this.onChange}
                        />

                        <FormInput
                            name="q1"
                            type="radio"
                            value={q1}
                            label="Manager"
                            valueOptions={
                                [
                                    { name: 'Angela', value: 'Angela'},
                                    { name: 'Jean', value: 'Jean'},
                                    { name: 'Juan', value: 'Juan'},
                                    { name: 'Morgan', value: 'Morgan'},
                                    { name: 'Other', value: 'Other'},
                                ]
                            }
                            colSize={1}
                            onChange={this.onChange}
                        />

                        <FormInput
                            name="q2"
                            type="radio"
                            value={q2}
                            label="Event Status"
                            valueOptions={
                                [
                                    { name: 'Active', value: 'Active'},
                                    { name: 'Cancelled - Weather', value: 'Cancelled - Weather'},
                                    { name: 'Cancelled - Lack of Participants', value: 'Cancelled - Participants'},
                                    { name: 'Other - Add to notes', value: 'Other - Add to notes'},
                                ]
                            }
                            colSize={1}
                            onChange={this.onChange}
                        />

                        <FormInput
                            name="q6"
                            type="textarea"
                            value={q6}
                            label="Any Additional Comments"
                            colSize={1}
                            onChange={this.onChange}
                        />



                        {errorMessage && <div className="row"><div className="col-12"><div className="alert alert-danger">{errorMessage}</div></div></div>}

                        <button className="btn btn-sm btn-success" onClick={()=>this.saveDailyLog()}>Save Log</button> {' '}  {' '}  {' '}
                        <button className="btn btn-sm btn-secondary" onClick={()=>this.cancelEditDailyLog()}>Cancel - Go Back</button>

                    </div>
                </div>
            </div>);
        }
    }
}

DailyLog.propTypes = {
    data: PropTypes.object.isRequired,
    saveDailyLog: PropTypes.func.isRequired,
    editDailyLog: PropTypes.func.isRequired,
    getDailyLogs: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    data: state.pages.data
});

export default connect( mapStateToProps, { saveDailyLog, editDailyLog, getDailyLogs })(DailyLog);
