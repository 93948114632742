import React, {Component, Fragment} from 'react';
import FormInput from "../form/FormInput";
import { ApiURL } from "../../config";
import {ToastContainer} from "react-toastr";
let container;

class CheckIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkInName: '',
            artistId: '',
            artistAmount: '',
            results: [],
            defaultEventPrice: 22
        };
        let intervalSearchHandle = null;
    }
    componentDidMount() {
        this.makeFocus();
    }


    onChange = (type, e, name) => {
        this.setState({[e.target.name]: e.target.value});
        this.startSearchTimer();
    };
    searchParticipants = () => {
        const formData = new FormData();
        formData.append("checkInName", this.state.checkInName);
        fetch(`${ApiURL}/getArtistName`, {
            method: 'POST',
            body: formData
        })
        .then(res => res.json())
        .then(output => {
            this.setState({results : output.Artists});
        });
    }
    startSearchTimer = () => {
        if (this.intervalSearchHandle !== null) this.stopSearchTimer();
        this.intervalSearchHandle = setTimeout(this.searchParticipants, 1000);
    };
    stopSearchTimer = () => {
        clearTimeout(this.intervalSearchHandle);
        this.intervalSearchHandle = null;
    };




    checkIn = (id, name, eventPrice) => {
        this.setState({ artistId: id, artistName: name});

        if (eventPrice === '' || typeof(eventPrice) === 'undefined') {
            eventPrice = this.state.defaultEventPrice;
        }
        if (eventPrice === '0' || eventPrice === 0 || eventPrice === '0.00') {
            this.saveCheckIn(id, 0);
        } else {
            eventPrice = eventPrice * 100;
            this.connectToSQCC(eventPrice, this.state.artistAmount);
        }
    };
    connectToSQCC = (totalAmount, customerName) => {
        this.setState({artistAmount: totalAmount});

        if (window.Android) {
            try {
                this.setState({ androidMessage: "Connecting to Square Checkout ...", readerConnected: true});
                window.Android.ChargeAmount(totalAmount, customerName);
                this.startTimer();
            }
            catch (e) {
                this.setState({ androidMessage: "Error running Square Checkout ...", readerConnected: false});
            }
        } else if (!window.Android) {
            this.setState({ androidMessage: "Couldn't connect to 'Android' App", readerConnected: false});
            container.error('Could not connect to the android application!');

            // REMOVE BELOW WHEN DONE TESTING CC
            // window.updateReaderData(true, 'success');
            // this.startTimer();
        }
    };
    saveCheckIn = (artistId, artistAmount) => {
        const formData = new FormData();
        formData.append("artistId", artistId);
        formData.append("artistAmount", artistAmount);
        fetch(`${ApiURL}/saveCheckIn`, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(output => {
                if (output.success === 1 || output.success === '1')
                {
                    container.success('You have been registered for todays event.');
                    this.onCancel();
                    this.makeFocus();
                } else {
                    container.error('There was an error registering you for todays event. ' + output.message);
                    this.onCancel();
                    this.makeFocus();
                }
            });
    };
    checkReaderData = () => {
        try {
            if (this.intervalHandle !== null) this.stopTimer();
            let readerData = window["getReaderData"]();
            if (readerData['success'] === false ||  readerData['success'] === "false")
            {
                this.startTimer();
            }
            else if (readerData['success'] === true || readerData['success'] === "true")
            {
                this.saveCheckIn(this.state.artistId, this.state.artistAmount);
            }
            else
            {
                this.startTimer();
            }
        }
        catch (e) {
            container.error("Unable to read window function");
            //this.setState({ androidMessage: "Unable to read window function" });
        }
    };

    startTimer = () => {
        if (this.intervalHandle !== null) this.stopTimer();
        this.intervalHandle = setTimeout(this.checkReaderData, 1000);
    };
    stopTimer = () => {
        clearTimeout(this.intervalHandle);
        this.intervalHandle = null;
    };
    onCancel = () => {
        clearTimeout(this.intervalHandle);
        this.setState({ artistId: '', artistAmount: '', checkInName: '', results: [] });
    };
    makeFocus = () => {
        window.checkInName.focus();
    };

    render() {
        const { checkInName, results, artistId } = this.state;
        return (<div className="container-fluid">
            <div className="row">
                <div className="col-12 text-right">
                    <a href="/login" className="btn btn-secondary">DASHBOARD</a>
                </div>
            </div>
            <div className="container">

                <div className="row justify-content-center">
                    <div className="col-12 my-4">
                        <h1 className="text-center text-white">Check In</h1>
                    </div>
                    <div className="col-12 col-md-6 bg-white pt-4 text-dark text-center">
                        <FormInput
                            type="inputLarge"
                            label="Artist"
                            name="checkInName"
                            value={checkInName}
                            onChange={this.onChange}
                            autoFocus='true'
                            ref={c => (this.checkInName = c)}
                        />

                        {checkInName && <Fragment>
                            <h3>Search Results</h3>
                            <hr/>
                        </Fragment>}

                        {results && checkInName && <Fragment>
                            {results.map((artist, index) => {
                                return (<div className="container my-4" key={index}>
                                    <div className="row">
                                        <div className="col-9">
                                            {artist.firstName} {artist.lastName}
                                        </div>
                                        <div className="col-3">
                                            { artist.status === 1 && <button
                                                className="btn btn-success btn-lg"
                                            >
                                                PAID
                                            </button>}
                                            { artist.status === 0 && <button
                                                onClick={()=> { this.checkIn(artist.id, artist.firstName + " " + artist.lastName, artist.eventPrice)}}
                                                className="btn btn-primary btn-lg"
                                            >
                                                CHECK IN
                                            </button>}
                                        </div>
                                    </div>
                                </div>);
                            })}
                        </Fragment>}

                        {checkInName && results.length === 0 && <div className="container my-4">
                            <div className="row">
                                <div className="col-12">
                                    No Results Found
                                </div>
                            </div>
                        </div>}

                        {artistId && <div className="container mb-4">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <button onClick={()=> { this.onCancel(); }} className="btn btn-lg btn-secondary">CANCEL</button>
                                </div>
                            </div>
                        </div>}

                    </div>
                </div>
            </div>
            <ToastContainer
                ref={ref => container = ref}
                className="toast-top-center"
            />
        </div>);
    }
}

export default CheckIn;
