import React, {Component, Fragment}  from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import { getStatsList, deleteStats } from "../../client/actions/apiActions";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'

class Stats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stats: [],
            successMessage: '',
            errorMessage: ''
        };
        this.selectedCheckboxes = new Set();
    };

    componentDidMount() {
        this.props.getStatsList();
    }
    componentWillUnmount() {
        this.setState({
            stats: []
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextState) {
        this.setState({
            stats: nextProps.data.stats
        });
    }

    deleteStats = (statsId, statsName) => {
        const deleteOptions = {
            title: 'Confirm Delete',
            message: `Are you sure you wish to delete this Stats "${statsName}"`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => { this.props.deleteStats(statsId); this.setState({successMessage: "Stats has been deleted!"}); }
                },
                {
                    label: 'No',
                    onClick: () => { console.log("Stats clicked no"); }
                }
            ]
        };

        confirmAlert(deleteOptions);
    };

    render() {
        const {
            stats,
            successMessage
        } = this.state;

            return (
                <div className="container content">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="text-center text-white mt-4">Stats</h1>

                            {successMessage && <div className="col-12"><div className="alert alert-success">{successMessage}</div></div>}

                            <Link className="btn btn-secondary my-2 mr-2" to="/settings"><i className="fas fa-long-arrow-alt-left" /> Back To Settings</Link>
                            <Link className="btn btn-warning my-2" to="/settings/stats/edit/0">Add Stats</Link>
                            <table className="table table-striped bg-white">
                                <thead className="thead-dark">
                                    <tr>
                                        <th>Name</th>
                                        <th>Price</th>
                                        <th className="text-center">Active</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {stats.length !== 0 && <Fragment>
                                    {stats.map((stats, index) => (
                                        <tr key={index}>
                                            <td>{stats.name}</td>
                                            <td>{stats.price}</td>
                                            {stats.active === '1' && <td className="text-center"><i className="text-success fas fa-check-circle" /></td>}
                                            {stats.active === '0' && <td className="text-center"><i className="text-danger fas fa-times-circle" /></td>}
                                            <td className="text-right">
                                                <Link className="btn btn-warning btn-sm m-1" to={"/settings/stats/edit/" + stats.id}><i className="fas fa-pencil-alt" /></Link>
                                                <button className="btn btn-danger btn-sm m-1" onClick={()=>this.deleteStats(stats.id, stats.firstName + " " + stats.lastName)}><i className="fas fa-times" /></button>
                                            </td>
                                        </tr>
                                    ))}
                                </Fragment>}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            );
    }
}

Stats.propTypes = {
    data: PropTypes.object.isRequired,
    getStatsList: PropTypes.func.isRequired,
    deleteStats: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    data: state.pages.data
});

export default connect( mapStateToProps, { getStatsList, deleteStats })(Stats);