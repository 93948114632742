import React, {Component} from 'react';
import {UncontrolledAlert} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell} from "@fortawesome/free-regular-svg-icons";
class ShowInputError extends Component {
    render() {
        return (<div className="col-12">
            <UncontrolledAlert
                color="danger"
                className="alert-outline-coloured pt-1"
            >
                <div className="alert-icon"><FontAwesomeIcon icon={faBell} fixedWidth /></div>
                <div className="alert-message">{this.props.error}</div>
            </UncontrolledAlert>
        </div>);
    }
}
export default ShowInputError;