import React, {Component}  from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { saveLocations, getLocations } from "../../client/actions/apiActions";

import Loading from "../layout/Loading"
import FormInput from "../form/FormInput";
import Link from "react-router-dom/Link";

class Locations_Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded:false,
            errorMessage: '',
            cancelEdit: false,
            errors : {},

            id: '',
            number: '',
            coords: '',
        }
    };
    componentDidMount() {
        const {id} = this.props.match.params;
        if (id !== '' && id !== 0 && id !== '0')
        {
            try {
                this.props.getLocations(id);
            }
            catch (e) {
                console.log("e", e);
            }
        }
        else
        {
            this.setState({id:0, isLoaded: true});
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data.location !== this.props.data.location && typeof(this.props.data.location) !== 'undefined') {
            this.setState({
                isLoaded: true,
                id: this.props.data.location.id,
                number: this.props.data.location.number,
                coords: this.props.data.location.coords,
            });
        }
        if (this.props.data.success === 1 ||this.props.data.success === '1') {
            this.props.history.push('/settings/locations');
        }
    }

    onChange = (type, e, name) => {
        let tmpValue = '';
        if (type === 'text')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'phone')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'email')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'textarea')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'toggle')
        {
            if (e.target.checked === true) tmpValue = '1';
            if (e.target.checked === false) tmpValue = '0';
        }
        else if (type === 'image')
        {
            tmpValue = e;
        }
        else if (type === 'editor')
        {
            tmpValue = e.target.getContent();
        }
        else if (type === 'select' || type === 'dropdown')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'password')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'checkbox')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'radio')
        {
            tmpValue = e.target.value;
        }
        else
        {
            tmpValue = e.target.value;
        }

        this.setState({[name]: tmpValue});
    };
    saveLocations = (e) => {
        if (typeof(e) !== 'undefined') e.preventDefault();
        const {id} = this.props.match.params;
        let noerror = true;
        let errors = {};
        const {
            number,
            coords,
        } = this.state;

        if (number === '') {
            noerror = false;
            errors = {...errors, number: 'You left the location "number" blank'};
        }

        if (noerror === true) {
            let saveData = {
                id,
                number,
                coords,
            };
            this.props.saveLocations( saveData );
            //this.props.history.push('/settings/locationss');
        } else {
            this.setState({errors});
        }
    };
    componentWillUnmount() {
        this.setState({
            isLoaded:false,
            errorMessage: '',
            cancelEdit: false,
            errors : {},

            id: '',
            number: '',
            coords: '',
        });
    }

    render() {
        const {
            errorMessage,
            errors,
            isLoaded,
            id,
            number,
            coords
        } = this.state;

        if (isLoaded === true) {
            return (
                <div className="container bg-white mt-4">
                    <form name="editHotelForm" id="editHotelForm" autoComplete="off" onSubmit={this.saveLocations}>
                    <div className="row">
                        <div className="col-12 mt-4">
                            {id !== 0 && id !== '0' && <h1 className="text-center">Edit Locations : {number}</h1>}
                            {(id === 0 || id === '0') && <h1 className="text-center">Add Locations</h1>}
                        </div>
                        {errorMessage && <div className="col-12"><div className="alert alert-danger">{errorMessage}</div></div>}
                        <div className="col-sm-6 text-left mb-3"><Link to="/settings/locationss" className="btn btn-secondary">Back</Link></div>
                        <div className="col-sm-6 text-right mb-3"><button type="button" className="btn btn-success" onClick={() => this.saveLocations()}>SAVE LOCATION</button></div>
                        <div className="col-12"><hr noshade="true" /></div>
                    </div>


                    <FormInput
                        type="text"
                        onChange={this.onChange}

                        label="Location Number"
                        id="number"
                        name="number"
                        value={number}
                        placeholder="enter location number .."
                        error={errors.number}
                    />
                    <FormInput
                        type="text"
                        onChange={this.onChange}

                        label="Coords"
                        id="coords"
                        name="coords"
                        value={coords}
                        placeholder="enter location coords .."
                        error={errors.coords}
                    />

                    <div className="row">
                        <div className="col-12"><hr noshade="true" /></div>
                        <div className="col-sm-6 text-left mb-3"><Link to="/settings/locationss" className="btn btn-secondary">Back</Link></div>
                        <div className="col-sm-6 text-right mb-3"><button type="button" className="btn btn-success" onClick={() => this.saveLocations()}>SAVE LOCATION</button></div>
                    </div>
                    </form>
                </div>
            )
        } else  {
            return (<Loading />);
        }
    }
}

Locations_Edit.propTypes = {
    data: PropTypes.object.isRequired,
    saveLocations: PropTypes.func.isRequired,
    getLocations: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    data: state.pages.data
});

export default connect( mapStateToProps, { saveLocations, getLocations })(Locations_Edit);