import React, {Component} from 'react';
import FormInput from "../form/FormInput";
import { ApiURL } from "../../config";
import {ToastContainer} from "react-toastr";
import Button from "react-bootstrap/Button";
let container;

class CheckIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chargeAmount: '',
            chargeDetails: ''
        };
        //let intervalHandle = null;
    }
    componentDidMount() {
        this.makeFocus();
    }
    onChange = (type, e, name) => {
        this.setState({[e.target.name]: e.target.value});
    };
    customCharge = () => {
        let totalAmount = this.state.chargeAmount * 100;
        this.connectToSQCC(totalAmount);
    }
    connectToSQCC = (totalAmount) => {
        if (window.Android) {
            try {
                this.setState({ androidMessage: "Connecting to Square Checkout ...", readerConnected: true});
                window.Android.ChargeAmount(totalAmount, this.state.chargeDetails);
                this.startTimer();
            }
            catch (e) {
                this.setState({ androidMessage: "Error running Square Checkout ...", readerConnected: false});
            }
        } else if (!window.Android) {
            this.setState({ androidMessage: "Couldn't connect to 'Android' App", readerConnected: false});
            container.error('Could not connect to the android application!');
        }
    };
    checkReaderData = () => {
        try {
            if (this.intervalHandle !== null) this.stopTimer();
            let readerData = window["getReaderData"]();
            if (readerData['success'] === false ||  readerData['success'] === "false")
            {
                this.startTimer();
            }
            else if (readerData['success'] === true || readerData['success'] === "true")
            {
                this.saveData();
            }
            else
            {
                this.startTimer();
            }
        }
        catch (e) {
            container.error("Unable to read window function");
            //this.setState({ androidMessage: "Unable to read window function" });
        }
    };
    saveData = () => {
        const formData = new FormData();
        formData.append("userId",  this.props.auth.user.user.id);
        formData.append("chargeAmount",  this.state.chargeAmount);
        formData.append("chargeDetails", this.state.chargeDetails);
        fetch(`${ApiURL}/saveCustomTransaction`, {
            method: 'POST',
            body: formData
        })
        .then(res => res.json())
        .then(output => {
            if (output.success === 1 || output.success === '1')
            {
                container.success('Transaction successful.');
                this.setState({ chargeAmount: '', chargeDetails: '' });
                window.location.replace("/");
                this.onCancel();
                this.makeFocus();
            } else {
                container.error('There was an error with the transaction. ' + output.message);
                this.onCancel();
                this.makeFocus();
            }
        });
    };

    startTimer = () => {
        if (this.intervalHandle !== null) this.stopTimer();
        this.intervalHandle = setInterval(this.checkReaderData, 1000);
    };
    stopTimer = () => {
        clearInterval(this.intervalHandle);
        this.intervalHandle = null;
    };
    onCancel = () => {
        clearInterval(this.intervalHandle);
        this.setState({ artistId: '', artistAmount: '', checkInName: '', results: [] });
    };
    makeFocus = () => {
        window.chargeAmount.focus();
    };

    render() {
        const { chargeAmount, chargeDetails } = this.state;
        return (<div className="container-fluid">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 my-4">
                        <h1 className="text-center text-white">custom credit card charge</h1>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6 bg-white pt-2 text-dark text-center">
                        <FormInput
                            type="number"
                            label="Dollar Amount $"
                            helpText={"Enter in the amount to charge the CC in dollars only - Example '5.00'"}
                            name="chargeAmount"
                            value={chargeAmount}
                            onChange={this.onChange}
                            autoFocus={true}
                            ref={c => (this.chargeAmount = c)}
                        />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6 bg-white pt-2 text-dark text-center">
                        <FormInput
                            type="text"
                            label="Description"
                            name="chargeDetails"
                            value={chargeDetails}
                            onChange={this.onChange}
                        />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6 bg-white py-4 text-dark text-center">
                        <Button onClick={this.customCharge}>Submit Transaction</Button>
                    </div>
                </div>
            </div>
            <ToastContainer
                ref={ref => container = ref}
                className="toast-top-center"
            />
        </div>);
    }
}

export default CheckIn;
