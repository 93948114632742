import React, {Component, Fragment}  from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import { getCategoryList, deleteCategory } from "../../client/actions/apiActions";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'

class Category extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            successMessage: '',
            errorMessage: ''
        };
        this.selectedCheckboxes = new Set();
    };

    componentDidMount() {
        this.props.getCategoryList();
    }
    componentWillUnmount() {
        this.setState({
            categories: []
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextState) {
        this.setState({
            categories: nextProps.data.categories
        });
    }

    deleteCategory = (categoryId, categoryName) => {
        const deleteOptions = {
            title: 'Confirm Delete',
            message: `Are you sure you wish to delete this Category "${categoryName}"`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => { this.props.deleteCategory(categoryId); this.setState({successMessage: "Category has been deleted!"}); }
                },
                {
                    label: 'No',
                    onClick: () => { console.log("Category clicked no"); }
                }
            ]
        };

        confirmAlert(deleteOptions);
    };

    render() {
        const {
            categories,
            successMessage
        } = this.state;

            return (
                <div className="container content">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="text-center text-white mt-4">Categories</h1>

                            {successMessage && <div className="col-12"><div className="alert alert-success">{successMessage}</div></div>}

                            <Link className="btn btn-secondary my-2 mr-2" to="/settings"><i className="fas fa-long-arrow-alt-left" /> Back To Settings</Link>
                            <Link className="btn btn-warning my-2" to="/settings/categories/edit/0">Add Category</Link>
                            <table className="table table-striped bg-white">
                                <thead className="thead-dark">
                                    <tr>
                                        <th>Name</th>
                                        <th>Price</th>
                                        <th className="text-center">Active</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {categories.length !== 0 && <Fragment>
                                    {categories.map((category, index) => (
                                        <tr key={index}>
                                            <td>{category.name}</td>
                                            <td>{category.price}</td>
                                            {category.active === '1' && <td className="text-center"><i className="text-success fas fa-check-circle" /></td>}
                                            {category.active === '0' && <td className="text-center"><i className="text-danger fas fa-times-circle" /></td>}
                                            <td className="text-right">
                                                <Link className="btn btn-warning btn-sm m-1" to={"/settings/categories/edit/" + category.id}><i className="fas fa-pencil-alt" /></Link>
                                                <button className="btn btn-danger btn-sm m-1" onClick={()=>this.deleteCategory(category.id, category.firstName + " " + category.lastName)}><i className="fas fa-times" /></button>
                                            </td>
                                        </tr>
                                    ))}
                                </Fragment>}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            );
    }
}

Category.propTypes = {
    data: PropTypes.object.isRequired,
    getCategoryList: PropTypes.func.isRequired,
    deleteCategory: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    data: state.pages.data
});

export default connect( mapStateToProps, { getCategoryList, deleteCategory })(Category);