import React, {Component, Fragment}  from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import {
    getParticipantList,
    deleteParticipant,
    getParticipantCalendar
} from "../../client/actions/apiActions";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import Calendar from "./Participant_Calendar";
import FormInput from "../form/FormInput";
import Loading from "../layout/Loading";

class Participant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCalendar: false,
            calendarId: 0,
            participants: [],
            successMessage: '',
            errorMessage: '',
            viewCalendarDate: '',
            calendar: [],
            eventStatus: [],
            viewMode: 'loading'
        };
        this.selectedCheckboxes = new Set();
    };

    componentDidMount() {
        this.props.getParticipantList();
        let newDate = new Date();
        let curMonth = String(newDate.getMonth()+1);
        if (curMonth.length === 1) curMonth = '0' + curMonth;
        let doy = newDate.getFullYear() + "-" + curMonth;
        this.setState({viewCalendarDate: doy})
    }
    componentWillUnmount() {
        this.setState({
            calendar: [],
            eventStatus: [],
            participants: [],
            successMessage: '',
            errorMessage: ''
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            if (this.props.data.pageType === 'getParticipantCalendar')
            {
                this.setState({
                    calendar: this.props.data.calendar,
                    eventStatus: this.props.data.eventStatus,
                    viewMode: 'list'
                })
            }
            if (this.props.data.pageType === 'getParticipantList')
            {
                this.setState({
                    participants: this.props.data.participants,
                    calendar: this.props.data.calendar,
                    eventStatus: this.props.data.eventStatus,
                    viewMode: 'list'
                })
            }
        }
    }

    deleteParticipant = (participantId, participantName) => {
        const deleteOptions = {
            title: 'Confirm Delete',
            message: `Are you sure you wish to delete this Participant "${participantName}"`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => { this.props.deleteParticipant(participantId); this.setState({successMessage: "Participant has been deleted!"}); }
                },
                {
                    label: 'No',
                    onClick: () => { console.log("Participant clicked no"); }
                }
            ]
        };
        confirmAlert(deleteOptions);
    };
    showCalendar = (pid) => {
        this.setState({showCalendar: true, calendarId: pid})
    }
    hideCalendar = () => {
        this.setState({showCalendar: false, calendarId: 0})
    }

    onChange = (type, e, name) => {
        this.setState({[name]: e.target.value});
        if (name === "viewCalendarDate") {
            this.props.getParticipantCalendar(this.state.calendarId, e.target.value);
        }
    };

    render() {
        const {
            calendar,
            showCalendar,
            calendarId,
            participants,
            viewCalendarDate,
            successMessage,
            eventStatus,
            viewMode
        } = this.state;

        let rand = Math.floor(Math.random() * 100);
        if (viewMode === 'loading') {
            return (<Loading />);
        }
        if (viewMode === 'list') {
            return (<div className="container content" key={rand}>
            <div className="row">
                <div className="col-12">
                    <h1 className="text-center text-white mt-4">Participants</h1>
                    {successMessage && <div className="col-12"><div className="alert alert-success">{successMessage}</div></div>}
                    <Link className="btn btn-secondary my-2 mr-2" to="/settings"><i className="fas fa-long-arrow-alt-left" /> Back To Settings</Link>
                    <Link className="btn btn-warning my-2" to="/settings/participants/edit/0">Add Participant</Link>
                    <table className="table table-striped bg-white">
                        <thead className="thead-dark">
                            <tr>
                                <th>Participant Name</th>
                                <th>Category</th>
                                <th>Group</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {participants.length !== 0 && participants.map((participant, index) => {
                                return (<Fragment key={index}>
                                    <tr>
                                        <td>{participant.firstName} {participant.lastName}</td>
                                        <td>{participant.categoryName}</td>
                                        <td className="text-center">{participant.lotteryGroup}</td>
                                        <td>{participant.phone}</td>
                                        <td>{participant.email}</td>
                                        <td className="text-right nowrap">
                                            <button onClick={() => this.showCalendar(participant.id) } className="btn btn-success btn-sm m-1"><i className="fas fa-calendar-alt" /></button>
                                            <Link className="btn btn-warning btn-sm m-1" to={"/settings/participants/edit/" + participant.id}><i className="fas fa-pencil-alt" /></Link>
                                            <button className="btn btn-danger btn-sm m-1" onClick={()=>this.deleteParticipant(participant.id, participant.firstName + " " + participant.lastName)}><i className="fas fa-times" /></button>
                                        </td>
                                    </tr>
                                    {showCalendar === true && calendarId === participant.id && <Calendar
                                        key={participant.id}
                                        calendarId={participant.id}
                                        hideCalendar={this.hideCalendar}
                                    />}
                                </Fragment>);
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>);
        }
    }
}

Participant.propTypes = {
    data: PropTypes.object.isRequired,
    getParticipantList: PropTypes.func.isRequired,
    deleteParticipant: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    data: state.pages.data
});

export default connect( mapStateToProps, { getParticipantList, deleteParticipant, getParticipantCalendar })(Participant);