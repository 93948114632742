import React, {Component}  from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { savePeople, getPeople } from "../../client/actions/apiActions";

import Loading from "../layout/Loading"
import {Redirect} from "react-router-dom";
import FormInput from "../form/FormInput";

class People extends Component {
    constructor(props) {
        super(props);
        this.state = {
            peopleData: {},
            successMessage: '',
            id: '',
            errorMessage: '',
            isLoaded: false,
            cancelEdit: false,
            user: '',
            name: '',
            email: '',
            pass: '',
            confirmPass: '',
            initials: '',
            phone: '',
            locations: '',
            saveData: '',
            admin: '1',
            manager: '0',
            supervisor: '0',
            selectedLocations: null,
            errors : {}
        };
        this.selectedCheckboxes = new Set();
    };
    componentDidMount() {
        const {id} = this.props.match.params;
        if (id !== 0 && id !== '0') {
            this.setState({ id: id});
            try {
                this.props.getPeople(id);
            }
            catch (e) {
                console.log("e", e);
            }
        } else {
            this.setState({isLoaded: true, id: 0});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            if (this.props.data.id !== '' && typeof(this.props.data.id) !== 'undefined')
            {
                let peopleData = this.props.data;
                this.setState({
                    isLoaded: true,
                    id: peopleData.id,
                    peopleData: peopleData,

                    name: peopleData.name,
                    initials: peopleData.initials,
                    email: peopleData.email,
                    phone: peopleData.phone,

                    locationList: peopleData.locationList,
                    locations: peopleData.locations,

                    admin: peopleData.admin,
                    supervisor: peopleData.supervisor,
                    manager: peopleData.manager,

                    user: peopleData.user,
                    password: peopleData.password
                });
            }

            if (this.props.data.success === 1) {
                this.props.history.push('/settings/users');
            }
        }
    }

    onChange = (type, e, name) => {
        let tmpValue = '';
        if (type === 'text')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'phone')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'email')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'textarea')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'toggle')
        {
            if (e.target.checked === true) tmpValue = '1';
            if (e.target.checked === false) tmpValue = '0';
        }
        else if (type === 'image')
        {
            tmpValue = e;
        }
        else if (type === 'editor')
        {
            tmpValue = e.target.getContent();
        }
        else if (type === 'select' || type === 'dropdown')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'password')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'checkbox')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'radio')
        {
            tmpValue = e.target.value;
        }
        else
        {
            tmpValue = e.target.value;
        }

        this.setState({[name]: tmpValue});
    };
    onChangeRadio = (name, value) => {
        this.setState({
            [name]: value
        });
    };
    onChangeToggle = (e) => {
        let newValue;
        if (e.target.checked === true) {
            newValue=1;
        } else {
            newValue=0;
        }
        this.setState({
            [e.target.name]:newValue
        });
    };
    savePeople = (e) => {
        if (typeof(e) !== 'undefined') e.preventDefault();
        const { id } = this.state.peopleData;
        const { user, name, email, pass, confirmPass, initials, phone, locations, admin, manager, supervisor } = this.state;

        let selectedLocations = ',';
        for (const checkbox of this.selectedCheckboxes) {
            selectedLocations += checkbox + ",";
        }

        let noerror = true;
        let errors = {};
        if (name === '') {
            noerror = false;
            errors = {...errors, name: 'You left the users "Name" blank'};
        }
        if (initials === '') {
            noerror = false;
            errors = {...errors, initials: 'You left the users "Initials" blank'};
        }
        if (user === '') {
            noerror = false;
            errors = {...errors, user: 'You left the users "Username" blank'};
        }

        if (pass !== '' && confirmPass !== '' && confirmPass !== pass) {
            noerror = false;
            errors = {...errors, confirmPass: 'Your passwords do not match'};
        }
        if ( confirmPass === '' && pass !== '') {
            noerror = false;
            errors = {...errors, confirmPass: 'Your did not confirm your "Password"'};
        }

        if (noerror === true) {
            let peopleData = { id, user, selectedLocations, name, email, pass, confirmPass, initials, phone, locations, admin, manager, supervisor };
            this.props.savePeople( peopleData );
        } else {
            this.setState({errors});
        }
    };

    cancelEdit = () => {
        this.setState({
            peopleData: {},
            successMessage: '',
            errorMessage: '',
            cancelEdit: true,
            user: '',
            name: '',
            email: '',
            pass: '',
            confirmPass: '',
            initials: '',
            phone: '',
            locations: '',
            admin: 0,
            manager: 0,
            supervisor: 0,
            selectedLocations: null
        });
        for (const checkbox of this.selectedCheckboxes) {
            if (this.selectedCheckboxes.has(checkbox)) {
                this.selectedCheckboxes.delete(checkbox);
            }
        }
    };
    handleCheckboxChange = label => {
        if (this.selectedCheckboxes.has(label)) {
            this.selectedCheckboxes.delete(label);
        } else {
            this.selectedCheckboxes.add(label);
        }
    };

    render() {
        const {
            errors,
            errorMessage,
            isLoaded,
            id,
            name,
            email,
            user,
            pass,
            password,
            confirmPass,
            initials,
            phone,
            admin,
            cancelEdit
        } = this.state;
        let userInfo = this.props.auth.user;

        if (cancelEdit === true) {
            return (<Redirect to="/settings/users"/> )
        } else if (isLoaded === true) {
            return (
                <div className="container content bg-white mt-4">
                    <form name="editPeopleForm" id="editPeopleForm" autoComplete="off" onSubmit={this.savePeople}>
                    <div className="row">
                        <div className="col-12">
                            {id !== 0 && id !== '0' && <h1 className="text-center">Edit User : {name}</h1>}
                            {(id === 0 || id === '0') && <h1 className="text-center">Add User</h1>}
                        </div>
                        {errorMessage && <div className="col-12"><div className="alert alert-danger">{errorMessage}</div></div>}
                        <div className="col-sm-6 text-left mb-3"><button type="button" className="btn btn-secondary" onClick={() => this.cancelEdit()}>Back</button></div>
                        <div className="col-sm-6 text-right mb-3"><button type="button" className="btn btn-success" onClick={() => this.savePeople()}>SAVE USER</button></div>
                        <div className="col-12"><hr noshade="true" /></div>
                    </div>

                    <h3>Personal Information</h3>
                    <FormInput
                        label="Full Name"
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        placeholder="Enter full name ..."
                        onChange={this.onChange}
                        error={errors.name}
                    />
                    <FormInput
                        label="Initials"
                        id="initials"
                        name="initials"
                        placeholder="Enter initials ..."
                        value={initials}
                        type="text"
                        onChange={this.onChange}
                        error={errors.initials}
                    />
                    <FormInput
                        label="Phone"
                        id="phone"
                        name="phone"
                        placeholder="Enter Phone Number ..."
                        value={phone}
                        type="text"
                        onChange={this.onChange}
                        error={errors.phone}
                    />
                    <FormInput
                        label="Email"
                        id="email"
                        name="email"
                        placeholder="Enter Email ..."
                        value={email}
                        type="text"
                        onChange={this.onChange}
                        error={errors.email}
                    />

                    <h3>Permissions</h3>
                    {userInfo.user.userType === 'A' &&
                        <div className="form-group row">
                            <div className="col-12">
                                <FormInput
                                    type="toggle"
                                    label="Admin"
                                    name="admin"
                                    onChange={this.onChange}
                                    selectedValue={admin}
                                    value={admin}
                                />
                            </div>
                        </div>}

                    <h3>User Credentials</h3>
                    <FormInput
                        label="Username"
                        id="user"
                        name="user"
                        placeholder="Enter username ..."
                        value={user}
                        type="text"
                        onChange={this.onChange}
                        error={errors.user}
                    />
                    {userInfo.user.userType === 'A' && id !== 0 && id !== '0' &&
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label" htmlFor="exampleFormControlInput1">Current Password</label>
                        <div className="col-sm-8">{password}</div>
                    </div>
                    }
                    <FormInput
                        label="Password"
                        id="pass"
                        name="pass"
                        placeholder="Enter password ..."
                        value={pass}
                        type="password"
                        onChange={this.onChange}
                        error={errors.pass}
                    />
                    <FormInput
                        label="Confirm Password"
                        id="confirmPass"
                        name="confirmPass"
                        placeholder="Confirm password ..."
                        value={confirmPass}
                        type="password"
                        onChange={this.onChange}
                        error={errors.confirmPass}
                    />

                    <div className="row">
                        <div className="col-12"><hr noshade="true" /></div>
                        <div className="col-sm-6 text-left mb-3"><button type="button" className="btn btn-secondary" onClick={() => this.cancelEdit()}>Back</button></div>
                        <div className="col-sm-6 text-right mb-3"><button type="button" className="btn btn-success" onClick={() => this.savePeople()}>SAVE USER</button></div>
                    </div>
                    </form>
                </div>
            )
        } else  {
            return (<Loading />)
        }
    }
}

People.propTypes = {
    peopleData: PropTypes.object.isRequired,
    saveData: PropTypes.object.isRequired,
    savePeople: PropTypes.func.isRequired,
    getPeople: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    peopleData: state.pages.peopleData,
    data: state.pages.data,
    saveData: state.pages.saveData
});

export default connect( mapStateToProps, { savePeople, getPeople })(People);