import React, {Component, Fragment}  from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { runLottery, getLotteryList, selectLotteryLocation, saveSelectLocation } from "../../client/actions/apiActions";
import 'react-confirm-alert/src/react-confirm-alert.css'

class RunLottery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            participants: [],
            locations: [],
            successMessage: '',
            artistId: '',
            artistName: '',
            errorMessage: '',
            selectLocation: false
        };
    };

    componentDidMount() {
        this.props.getLotteryList();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props)
        {
            if (this.props.data !== '' && typeof(this.props.data) !== 'undefined') {
                this.setState({
                    participants: this.props.data.participants,
                    locations: this.props.data.locations
                });
            }
            if (this.props.data.success === 1)
            {
                this.cancelSelectLocation();
            }
        }
    }
    componentWillUnmount() {
        this.setState({
            participants: [],
            locations: [],
            successMessage: '',
            artistId: '',
            artistName: '',
            errorMessage: '',
            selectLocation: false
        });
    }

    selectLotteryLocation = (artistId, artistName) => {
        this.setState({selectLocation: true, artistId:artistId, artistName: artistName});
    };
    selectLocation = (locationId) => {
        let postData = {
            artistId: this.state.artistId,
            locationId: locationId
        };
        this.props.saveSelectLocation( postData );
    };
    cancelSelectLocation = () => {
        this.setState({selectLocation: false, artistId: '', artistName: ''});
    };

    render() {
        const {
            participants,
            successMessage,
            selectLocation,
            artistName,
            locations
        } = this.state;
        let tmpLotteryGroup='';
        let groupText='';
        let runLotteryStatus = false;
        if (participants.length !== 0) {
            participants.map((participant, index) => {
                if (participant.lotteryPosition !== '' && participant.lotteryPosition !== null) {
                    runLotteryStatus = true;
                }
                return (null);
            });
        }
        if (selectLocation === false) {
            return (<div className="container content">
                <div className="row">
                    <div className="col-12">
                        <h1 className="text-center text-white mt-4">Event Lottery</h1>
                        {successMessage && <div className="col-12"><div className="alert alert-success">{successMessage}</div></div>}
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col-12 text-center">
                        {runLotteryStatus === false && <button className="btn btn-warning btn-block my-2" onClick={() => this.props.runLottery()}>Run Lottery</button>}
                        {runLotteryStatus === true && <div className="container bg-white rounded border p-4">
                            {participants.length !== 0 && <Fragment>
                                {participants.map((participant, index) => {
                                    if (participant.lotteryGroup !== tmpLotteryGroup) {
                                        tmpLotteryGroup = participant.lotteryGroup;
                                        groupText = 'Group ' + participant.lotteryGroup;
                                    } else {
                                        groupText = '';
                                    }
                                    return (<div key={index} className="row py-1">
                                        {groupText && <div className="col-12 mt-5"><h3>{groupText}</h3></div>}
                                        <div className="col-6">{participant.firstName} {participant.lastName}</div>
                                        <div className="col-6">
                                            {participant.location !== '' && typeof(participant.location) !== 'undefined' && participant.location !== null && <Fragment>{participant.location}</Fragment>}
                                            {participant.location === '' && <button className="btn btn-warning btn-lg m-1" onClick={()=>this.selectLotteryLocation(participant.artistId, participant.firstName + " " + participant.lastName)}>select location</button>}
                                        </div>
                                    </div>);
                                })}
                            </Fragment>}
                        </div>}
                    </div>
                </div>
            </div>);
        } else {
            return (<div className="container content">
                <div className="row">
                    <div className="col-12">
                        <h1 className="text-center text-white mt-4">Selecting Location for {artistName}</h1>
                        <h3 className="text-center text-white">Locations Remaining</h3>
                    </div>
                </div>

                <div className="row text-center">
                    <div className="col-12 bg-white mr-auto text-center py-4">
                        <button className="btn btn-secondary btn-lg" onClick={() => this.cancelSelectLocation()}>Cancel</button>
                        {locations.map((location, index) => {
                            return (<div className="row my-2">
                                <div className="col-6">
                                    {location.number}
                                </div>
                                <div className="col-6">
                                    <button className="btn btn-warning btn-lg" onClick={() => this.selectLocation(location.id)}>select</button>
                                </div>
                            </div>);
                        })}
                    </div>
                </div>
            </div>);
        }


    }
}

RunLottery.propTypes = {
    data: PropTypes.object.isRequired,
    getLotteryList: PropTypes.func.isRequired,
    runLottery: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    data: state.pages.data,
});

export default connect( mapStateToProps, { getLotteryList, runLottery, selectLotteryLocation, saveSelectLocation })(RunLottery);