import { combineReducers } from 'redux';

import pagesReducer from './pagesReducer';
import authReducer from "./authReducer"
import errorsReducer from "./errorsReducer"
import messageReducer from "./messageReducer"
import { reducer as toastr } from "react-redux-toastr";

export default combineReducers({
    pages: pagesReducer,
    auth: authReducer,
    errors: errorsReducer,
    messages: messageReducer,
    toastr
})