import React, {Component} from 'react';
import {Link} from "react-router-dom";
class Settings extends Component {
    render() {
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 my-4">
                        <h1 className="text-center text-white">Admin Dashboard</h1>
                    </div>
                    <div className="col-12 text-center">
                        <Link to="/settings/participants" className="btn btn-light m-2">
                            Participants
                        </Link>
                        <Link to="/settings/reports" className="btn btn-light m-2">
                            Reports
                        </Link>
                        <Link to="/settings/users" className="btn btn-light m-2">
                            Users
                        </Link>
                        <Link to="/settings/categories" className="btn btn-light m-2">
                            Categories
                        </Link>
                        <Link to="/settings/global-settings" className="btn btn-light m-2">
                            Reset Check-In
                        </Link>
                        <br /><br />
                    </div>
                </div>
            </div>
        )
    }
}
export default Settings;