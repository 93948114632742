export const GET_UNITS = 'GET_UNITS';

export const GET_LOGIN_STATUS = 'GET_LOGIN_STATUS';

// MESSAGE
export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const GET_ERRORS = 'GET_ERRORS';
export const PAGE_ERROR = 'PAGE_ERROR';

// USER AUTH
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

// PARTICIPANT
export const GET_PARTICIPANT_LIST = 'GET_PARTICIPANT_LIST';
export const GET_PARTICIPANT_CALENDAR = 'GET_PARTICIPANT_CALENDAR';
export const GET_PARTICIPANT = 'GET_PARTICIPANT';
export const SAVE_PARTICIPANT = 'SAVE_PARTICIPANT';
export const SAVE_PARTICIPATION = 'SAVE_PARTICIPATION';
export const SAVE_PARTICIPANT_ERROR = 'SAVE_PARTICIPANT_ERROR';
export const DELETE_PARTICIPANT_ERROR = 'DELETE_PARTICIPANT_ERROR';
export const DELETE_PARTICIPANT = 'DELETE_PARTICIPANT';

// PEOPLE
export const GET_PEOPLE_LIST = 'GET_PEOPLE_LIST';
export const GET_PEOPLE = 'GET_PEOPLE';
export const SAVE_PEOPLE = 'SAVE_PEOPLE';
export const SAVE_PEOPLE_SUCCESS = 'SAVE_PEOPLE_SUCCESS';
export const SAVE_PEOPLE_ERROR = 'SAVE_PEOPLE_ERROR';
export const DELETE_PEOPLE_ERROR = 'DELETE_PEOPLE_ERROR';
export const DELETE_PEOPLE = 'DELETE_PEOPLE';

// DATA
export const GET_DATA_LIST = 'GET_DATA_LIST';
export const GET_DATA = 'GET_DATA';
export const SAVE_DATA = 'SAVE_DATA';
export const SAVE_DATA_SUCCESS = 'SAVE_DATA_SUCCESS';
export const SAVE_DATA_ERROR = 'SAVE_DATA_ERROR';
export const DELETE_DATA_ERROR = 'DELETE_DATA_ERROR';
export const DELETE_DATA = 'DELETE_DATA';

// STATS
export const GET_STATS_LIST = 'GET_STATS_LIST';
export const GET_STATS = 'GET_STATS';
export const SAVE_STATS = 'SAVE_STATS';
export const SAVE_STATS_SUCCESS = 'SAVE_STATS_SUCCESS';
export const SAVE_STATS_ERROR = 'SAVE_STATS_ERROR';
export const DELETE_STATS_ERROR = 'DELETE_STATS_ERROR';
export const DELETE_STATS = 'DELETE_STATS';