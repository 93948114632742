import React, {Component}  from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { saveCategory, getCategory } from "../../client/actions/apiActions";
import Loading from "../layout/Loading"
import FormInput from "../form/FormInput";
import Link from "react-router-dom/Link";
import 'react-image-lightbox/style.css';

class Category_Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded:false,
            errorMessage: '',
            cancelEdit: false,
            errors: [],
            id: '',
            name: '',
            active: '',
            price: ''
        }
    };
    componentDidMount() {
        const {id} = this.props.match.params;
        if (id !== '' && id !== 0) {
            try {
                this.props.getCategory(id);
            }
            catch (e) {
                console.log("e", e);
            }
        } else {
            this.setState({isLoaded: true});
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data.category !== this.props.data.category && typeof(this.props.data.category) !== 'undefined') {
            let active = false;
            let dataActive = this.props.data.category.active;
            if (dataActive === 1 || dataActive === '1') active = true;
            if (dataActive === 0 || dataActive === '0') active = false;

            this.setState({
                isLoaded: true,
                id: this.props.data.category.id,
                name: this.props.data.category.name,
                price: this.props.data.category.price,
                active: active,
            });
        }
        if (this.props.data.success === 1 ||this.props.data.success === '1') {
            this.props.history.push('/settings/categories');
        }

    }
    componentWillUnmount() {
        this.setState({
            isLoaded:false,
            errorMessage: '',
            cancelEdit: false,

            id: '',
            name: '',
            active: false,
        });
    }


    onChange = (type, e, name) => {
        let tmpValue = '';
        if (type === 'text')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'phone')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'email')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'textarea')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'toggle')
        {
            if (e.target.checked === true) tmpValue = '1';
            if (e.target.checked === false) tmpValue = '0';
        }
        else if (type === 'image')
        {
            tmpValue = e;
        }
        else if (type === 'editor')
        {
            tmpValue = e.target.getContent();
        }
        else if (type === 'select' || type === 'dropdown')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'password')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'checkbox')
        {
            tmpValue = e.target.value;
        }
        else if (type === 'radio')
        {
            tmpValue = e.target.value;
        }
        else
        {
            tmpValue = e.target.value;
        }

        this.setState({[name]: tmpValue});
    };
    onChangeToggle = (e) => {
        let newValue;
        if (e.target.checked === true) {
            newValue=1;
        } else {
            newValue=0;
        }
        this.setState({
            [e.target.name]:newValue
        });
    };

    saveCategory = (e) => {
        if (typeof(e) !== 'undefined') e.preventDefault();
        const {id} = this.props.match.params;
        let noerror = true;
        let errors = {};
        const {
            name,
            active,
            price
        } = this.state;

        if (name === '') {
            noerror = false;
            errors = {...errors, firstName: 'You left the category "Name" blank'};
        }

        if (noerror === true) {
            let saveData = {
                id,
                name,
                active,
                price
            };
            this.props.saveCategory( saveData );
        } else {
            this.setState({errors});
        }
    };

    render() {
        const {
            errorMessage,
            errors,

            id,
            name,
            active,
            price,

            isLoaded
        } = this.state;

        if (isLoaded === true) {
            return (
                <div className="container content bg-white mt-4">
                    <form name="editHotelForm" id="editHotelForm" autoComplete="off" onSubmit={this.saveCategory}>
                    <div className="row">
                        <div className="col-12 mt-4">
                            {id !== 0 && <h1 className="text-center">Edit Category : {name}</h1>}
                            {id === 0 && <h1 className="text-center">Add Category</h1>}
                        </div>
                        {errorMessage && <div className="col-12"><div className="alert alert-danger">{errorMessage}</div></div>}
                        <div className="col-sm-6 text-left mb-3"><Link to="/settings/categories" className="btn btn-secondary">Back</Link></div>
                        <div className="col-sm-6 text-right mb-3"><button type="button" className="btn btn-success" onClick={() => this.saveCategory()}>SAVE CATEGORY</button></div>
                        <div className="col-12"><hr noshade="true" /></div>
                    </div>

                    <FormInput
                        type="text"
                        onChange={this.onChange}

                        label="Name"
                        id="name"
                        name="name"
                        value={name}
                        placeholder="enter category name .."
                        error={errors.name}
                    />
                    <FormInput
                        type="toggle"
                        onChange={this.onChange}

                        label="Active"
                        id="active"
                        name="active"
                        value={active}
                        defaultValue={1}
                        error={errors.active}
                    />
                    <FormInput
                        type="text"
                        onChange={this.onChange}

                        label="Price"
                        id="price"
                        name="price"
                        value={price}
                        placeholder="enter price .."
                        error={errors.price}
                    />
                    <div className="row">
                        <div className="col-12"><hr noshade="true" /></div>
                        <div className="col-sm-6 text-left mb-3"><Link to="/settings/categories" className="btn btn-secondary">Back</Link></div>
                        <div className="col-sm-6 text-right mb-3"><button type="button" className="btn btn-success" onClick={() => this.saveCategory()}>SAVE CATEGORY</button></div>
                    </div>
                    </form>
                </div>
            )
        } else  {
            return (<Loading />);
        }
    }
}

Category_Edit.propTypes = {
    data: PropTypes.object.isRequired,
    saveCategory: PropTypes.func.isRequired,
    getCategory: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    data: state.pages.data
});

export default connect( mapStateToProps, { saveCategory, getCategory })(Category_Edit);