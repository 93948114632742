import React, {Component, Fragment} from 'react';

class Dashboard extends Component {
    componentDidMount() {
    }

    render() {
        const {user} = this.props.auth;
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 my-4">
                        <h1 className="text-center text-white">Dashboard</h1>
                    </div>

                    <div className="col-12 text-center">
                        <a href="/check-in" className="btn btn-lg btn-block btn-light">
                            Participant <br />Check-In
                        </a>

                        <br /><br />

                        <a href="/run-lottery" className="btn btn-lg btn-block btn-light">
                            View <br />Lottery
                        </a>

                        <br /><br />

                        <a href="/cash-register" className="btn btn-lg btn-block btn-light">
                            Custom CC<br/> Charge
                        </a>

                        <br /><br />

                        <a href="/log" className="btn btn-lg btn-block btn-light">
                            DAILY<br />LOG
                        </a>

                        {user.user.userType === 'A' && <Fragment>
                        <br /><br />
                        <a href="/settings" className="btn btn-lg btn-block btn-light">
                            Admin <br />Dashboard
                        </a>
                        </Fragment>}

                        <br /><br />
                    </div>
                </div>
            </div>
        )
    }
}

export default Dashboard;
